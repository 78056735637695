import { axiosSimple } from 'src/api/axios';
import { ENDPOINT_MICROSERVICE } from 'src/constants/api';

import { post } from './api';

export const sendFeedback = async ({
  email,
  message,
  userId,
  isSubscriber,
  fromLabel = 'Mimika Web',
}: {
  email: string;
  fromLabel?: string;
  message: string;
  userId?: number;
  isSubscriber?: boolean;
}): Promise<void> =>
  await post({
    endpoint: ENDPOINT_MICROSERVICE,
    path: 'feedback',
    body: {
      from_label: fromLabel,
      user_email: email,
      user_id: userId,
      form_name: 'Mimika',
      message: message,
      is_subscriber: isSubscriber,
    },
  });

export const updateUserInfo = async ({
  userData,
  authToken,
}: {
  userData: Record<string, any>;
  authToken: string;
}): Promise<void> => {
  try {
    const { data } = await axiosSimple.put(
      'update_user_info/',
      {
        user_data: userData,
      },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
      },
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};
