import classNames from 'classnames';
import React, { MouseEvent, useCallback, useState } from 'react';
import { Image } from 'src/components/common/Image';
import { FormattedMessage } from 'src/features/intl';
import styles from './compare.module.scss';
const isInViewport = (left: number, right: number) => left >= 0 && right <= (window.innerWidth || document.documentElement.clientWidth);
const photos = [<Image className={styles.photo} key={1} src="reviews-1-slider-paywall.webp" layout="fill" alt="before-after" />, <Image className={styles.photo} key={2} src="reviews-2-slider-paywall.webp" layout="fill" alt="before-after" />, <Image className={styles.photo} key={3} src="reviews-3-slider-paywall.webp" layout="fill" alt="before-after" />, <Image className={styles.photo} key={4} src="reviews-4-slider-paywall.webp" layout="fill" alt="before-after" />, <Image className={styles.photo} key={5} src="reviews-5-slider-paywall.webp" layout="fill" alt="before-after" />];
const photosLength = photos.length;
export const Compare = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onScroll = useCallback((event: MouseEvent<HTMLUListElement>) => {
    for (const li of event.currentTarget.children) {
      const {
        left,
        right
      } = li.getBoundingClientRect();
      if (isInViewport(left, right)) {
        setActiveIndex((li as HTMLLIElement).value);
      }
    }
  }, []);
  const onClick = useCallback((event: MouseEvent<HTMLLIElement>) => {
    event.currentTarget.scrollIntoView({
      inline: 'center',
      block: 'nearest',
      behavior: 'smooth'
    });
    setActiveIndex(event.currentTarget.value);
  }, []);
  return <>
      <h2 className={styles.title}>
        <FormattedMessage id="Onboarding.Paywall.Main.Result.Title" defaultMessage="You can enjoy results {br} like these!" values={{
        br: <br />
      }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </h2>
      <p className={styles.description}>
        <FormattedMessage defaultMessage="Here’s how your face can change after {br} completing your program" id="Onboarding.Paywall.Main.Result.Text" values={{
        br: <br />
      }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </p>
      <ul className={styles.photosList} onScroll={onScroll}>
        {photos.map((photo, index) => <li onClick={onClick} className={styles.photoItem} key={index} value={index}>
            <div className={styles.headerPhoto}>
              <p>
                <FormattedMessage id="Onboarding.Paywall.Main.Result.Before" defaultMessage="Before" />
              </p>
              <p>
                <FormattedMessage id="Onboarding.Paywall.Main.Result.After" defaultMessage="After" />
              </p>
            </div>
            {photo}
          </li>)}
      </ul>
      <div className={styles.pagination}>
        {Array.from({
        length: photosLength
      }, (_, index) => <button className={classNames(styles.paginationButton, activeIndex === index && styles.paginationButtonActive)} key={index} />)}
      </div>
    </>;
};