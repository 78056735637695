const CLOUDFRONT_PROD = 'https://d10yd08thvihvn.cloudfront.net/';
const CLOUDFRONT_TEST = 'https://d2tozmh86m2ekf.cloudfront.net/';
// const CLOUDFRONT_TEST = '/';

type TpImagePath = 'images' | 'icons' | 'videos';

const getImageUrl = (path: TpImagePath = 'images') =>
  process.env.NEXT_PUBLIC_ENV !== 'development'
    ? `${CLOUDFRONT_PROD}${path}/`
    : `${CLOUDFRONT_TEST}${path}/`;

export const IMAGE_SOURCE = getImageUrl();
export const ICON_SOURCE = getImageUrl('icons');
export const VIDEO_SOURCE = getImageUrl('videos');
