import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { FormattedMessage } from 'src/features/intl';
import { stripePaymentErrorAtom } from '@features/Stripe/atoms';
import ClockIcon from '../../../../assets/icons/clock.svg';
import styles from './banner.module.scss';
const TIME_DURATION_MINUTES = 4;
const TIME_DURATION_SECONDS = 59;
export enum BannerType {
  DEFAULT,
  RED,
}
type Props = {
  onClick?: () => void;
  variant?: BannerType;
  onFinish?: () => void;
};
const leadingZero = (numerous: number) => numerous < 10 ? `0${numerous}` : `${numerous}`;
const Banner = observer(({
  onClick,
  variant = BannerType.DEFAULT,
  onFinish
}: Props) => {
  const {
    authStore: {
      variant: onboardingVariant
    }
  } = useStores();
  const {
    wasErrorModalWithOfferShown
  } = useAtomValue(stripePaymentErrorAtom);
  const [seconds, setSeconds] = useState(TIME_DURATION_SECONDS);
  const [minutes, setMinutes] = useState(TIME_DURATION_MINUTES);
  const [timerEnd, setTimerEnd] = useState(false);
  const [firstTimerEnd, setFirstTimerEnd] = useState(false);
  useEffect(() => {
    let interval = setInterval(() => {
      setSeconds(previous => --previous);
      if (wasErrorModalWithOfferShown || onboardingVariant === 'email2') {
        setTimerEnd(true);
        clearInterval(interval);
        return;
      }
      if (!timerEnd && seconds === 0) {
        setSeconds(TIME_DURATION_SECONDS);
        setMinutes(previous => --previous);
      }
      if (seconds === 0 && minutes === 0) {
        setMinutes(TIME_DURATION_MINUTES);
        clearInterval(interval);
        setFirstTimerEnd(true);
      }
      if (seconds === 0 && minutes === 0) {
        setTimerEnd(true);
        clearInterval(interval);
        onFinish?.();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, minutes, timerEnd, onFinish, firstTimerEnd, wasErrorModalWithOfferShown, onboardingVariant]);
  return useMemo(() => {
    if (variant === BannerType.RED) {
      return <div className={styles.discountSection}>
            <ClockIcon width={20} height={20} />
            {timerEnd ? <FormattedMessage defaultMessage="Special offer just for you!" id="Onboarding.Paywall.Main.Red.Timer2" /> : <>
                <FormattedMessage defaultMessage="This offer is available within" id="Onboarding.Paywall.Main.Red.Timer" />{' '}
                {`${leadingZero(minutes)}:${leadingZero(seconds)}`}
              </>}
          </div>;
    }
    return <div className={styles.banner}>
          <div className={classNames(styles.timer, timerEnd && styles.timerEnd)}>
            {wasErrorModalWithOfferShown ? <p>Discount activated:</p> : timerEnd ? <p>
                <FormattedMessage defaultMessage="Personal discount is still available to you!" id="Onboarding.Paywall.Main.Timer2" />
              </p> : <div className={styles.bannerLeft}>
                <p>
                  <FormattedMessage defaultMessage="Discount available:" id="Onboarding.Paywall.Main.Timer" />
                </p>{' '}
                <p>{`${leadingZero(minutes)}:${leadingZero(seconds)}`}</p>
              </div>}
          </div>
          <button onClick={onClick} className={classNames(styles.linkButton)}>
            <FormattedMessage id="Onboarding.Paywall.Main.Timer.Button" defaultMessage="GET MY PLAN" />
          </button>
        </div>;
  }, [minutes, onClick, seconds, timerEnd, variant, wasErrorModalWithOfferShown]);
});
export default Banner;