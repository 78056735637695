import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

export const userAtom = atomWithStorage('stripeUser', {
  ...createJSONStorage(() => sessionStorage),
  customerId: '',
  email: '',
  token: '',
  campaign: '',
  userId: 0,
});

type TpSelectedPlanAtom = {
  currency: string;
  planName: string;
  discountPercentage: number;
  fullPrice: number;
  fullPriceDiscount: number;
  billingPeriod: number;
  billingPeriodUnit: string;
  specialDiscountPercentage: number;
  priceId: string;
  introOffer: string;
  braintreePlanId: undefined | string;
  braintreeMerchantId: undefined | string;
  specialOffer: boolean;
  introOfferData: {
    fullPrice: number;
    fullPriceDiscount: number;
    currency: string;
    introOfferDescription: string;
    introOfferDuration: string;
    introOfferId: string;
    billingPeriod: number;
    billingPeriodUnit: string;
  };
};

export const selectedPlanAtom = atom<TpSelectedPlanAtom>({
  currency: 'usd',
  planName: '',
  discountPercentage: 0,
  fullPrice: 0,
  fullPriceDiscount: 0,
  billingPeriod: 0,
  billingPeriodUnit: '',
  specialDiscountPercentage: 0,
  priceId: '',
  introOffer: '',
  braintreePlanId: undefined,
  braintreeMerchantId: undefined,
  specialOffer: false,
  introOfferData: {
    fullPrice: 0,
    fullPriceDiscount: 0,
    introOfferDescription: '',
    currency: 'usd',
    introOfferDuration: '',
    introOfferId: '',
    billingPeriod: 0,
    billingPeriodUnit: '',
  },
});

export const upsellInfoAtom = atomWithStorage('stripeUpsellInfo', {
  ...createJSONStorage(() => sessionStorage),
  currency: 'USD',
  discountPercentage: 0,
  fullPrice: 0,
  fullPriceDiscount: 0,
});

export const noMoneyPlanInfoAtom = atomWithStorage<{
  currency: string;
  discountPercentage: number | null;
  fullPrice: number;
  fullPriceDiscount: number;
  priceFromDefaultOneMonth: number;
}>('stripeNoMoneyInfo', {
  ...createJSONStorage(() => sessionStorage),
  currency: 'USD',
  discountPercentage: 0,
  fullPrice: 0,
  fullPriceDiscount: 0,
  priceFromDefaultOneMonth: 0,
});

export const upgradeInfoAtom = atomWithStorage('stripeUpgradeInfo', {
  ...createJSONStorage(() => sessionStorage),
  currency: 'USD',
  discountPercentage: 0,
  fullPrice: 0,
  fullPriceDiscount: 0,
});

export const introOfferInfoAtom = atomWithStorage('stripeIntroOfferInfo', {
  ...createJSONStorage(() => sessionStorage),
  currency: 'usd',
  fullPriceDiscount: 0,
  introOfferDuration: 0,
  introOfferId: '',
  planId: '',
  billingPeriodUnit: '',
  billingPeriod: 0,
});

export const processingModalStatusAtom = atom(false);

export const stripeWasPaymentTriedAtom = atomWithStorage(
  'stripeWasPaymentTried',
  {
    ...createJSONStorage(() => sessionStorage),
    oneTimeOfferShow: false,
    wasTried: false,
    showCrossSailOffer: false,
    wasUpsellSailOfferShown: false,
    isSpecialOfferFlow: false,
  },
);

export const stripePaymentErrorAtom = atomWithStorage('stripePaymentError', {
  ...createJSONStorage(() => sessionStorage),
  wasErrorModalWithOfferShown: false,
  errorCode: '',
  declineCode: '',
  paymentSystem: '',
  paymentMethod: '',
});

export const stripeSubscription = atom(
  typeof window !== 'undefined' && (sessionStorage.getItem('myKey') ?? ''),
);

export const paymentMethod = atom(
  typeof window !== 'undefined' &&
    (sessionStorage.getItem('btPaymentMethod') ?? ''),
);

export const stripeSubscriptionWithPersistence = atomWithStorage<string>(
  'myKey',
  stripeSubscription as unknown as string,
);

export const paymentMethodWithPersistence = atom(
  (get) => get(paymentMethod),
  (get, set, newString: string) => {
    set(paymentMethod, newString);
    sessionStorage.setItem('btPaymentMethod', newString);
  },
);
