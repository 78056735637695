import classNames from 'classnames';
import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { SubscriptionCardMaster } from 'src/features/Paywall';
import { TpPlanCardProps } from 'src/features/Paywall/types';
import { CurrentPrice } from 'src/features/Paywall/widgets/subscription-card/current-price';
import { PriceUnitFull } from 'src/features/Paywall/widgets/subscription-card/price-unit-full';
import { BillingPeriod } from '@features/Paywall/widgets/subscription-card/billing-period';
import { DiscountPercentage } from '@features/Paywall/widgets/subscription-card/discount-percentage';
import { FullPrice } from '@features/Paywall/widgets/subscription-card/full-price';
import styles from './subscription-plan-card.module.scss';
export const SubscriptionPlanCard = (props: TpPlanCardProps) => {
  const {
    plan,
    disabled = false
  } = props;
  return <SubscriptionCardMaster {...props} planCardClassname={styles.planCard} contentClassname={styles.content} leftBottomContent={value => !disabled && <div className={styles.wrapper}>
            <div className={classNames(styles.text, {
      [styles.textChecked]: value
    })}>
              {plan?.fractionalPrice !== plan?.fractionalPriceDiscount && <FullPrice plan={plan} />}{' '}
              <FullPrice withDiscount plan={plan} /> per{' '}
              <BillingPeriod plan={plan} />
            </div>
            {plan?.discountPercentage !== 0 && plan?.discountPercentage != null && <DiscountPercentage discountPercentage={plan.discountPercentage} isActive={value} wasPaymentTried={false} />}
          </div>} rightContent={value => <div className={styles.alignEnd}>
          <div className={styles.rightBlockContainer}>
            {plan?.fractionalPrice !== plan?.fractionalPriceDiscount && <PriceUnitFull plan={plan} checked={value} className={styles.priceUnitFull} />}
            <CurrentPrice plan={plan} checked={value} />
          </div>
        </div>} data-sentry-element="SubscriptionCardMaster" data-sentry-component="SubscriptionPlanCard" data-sentry-source-file="index.tsx" />;
};