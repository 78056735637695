import React from 'react';
import AmexLogo from '../../../../../../assets/icons/amex.svg';
import MastercardLogo from '../../../../../../assets/icons/mastercard.svg';
import PayPalLogo from '../../../../../../assets/icons/paypal.svg';
import UnionPayLogo from '../../../../../../assets/icons/unionpay.svg';
import VisaLogo from '../../../../../../assets/icons/visa.svg';
import styles from './debit-credit-block.module.scss';
export const DebitCreditBlock = ({
  minimalStyle
}: {
  minimalStyle?: boolean;
}) => <div className={styles.debitCreditBlock} data-sentry-component="DebitCreditBlock" data-sentry-source-file="index.tsx">
    {!minimalStyle && <p>Debit / credit card</p>}
    <figure className={styles.logosContainer}>
      <VisaLogo className={styles.visaLogo} data-sentry-element="VisaLogo" data-sentry-source-file="index.tsx" />
      <MastercardLogo className={styles.mastercardLogo} data-sentry-element="MastercardLogo" data-sentry-source-file="index.tsx" />
      {!minimalStyle && <PayPalLogo className={styles.paypalLogo} />}
      <AmexLogo className={styles.amexLogo} data-sentry-element="AmexLogo" data-sentry-source-file="index.tsx" />
      <UnionPayLogo className={styles.unionpayLogo} data-sentry-element="UnionPayLogo" data-sentry-source-file="index.tsx" />
    </figure>
  </div>;