import { observer } from 'mobx-react-lite';
import React, { ComponentProps, ReactNode, useCallback, useMemo, useState } from 'react';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { PaymentMethod } from 'src/models/payments-store/payments-store';
import type { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';
import { usePrevious } from 'src/utils/hooks/use-previous';
import { useAnalytics } from '@features/Analytics';
import { AnalyticsPaymentMethod, PaymentSystem, handleCheckoutOpenRequestWithPaymentSystem, usePaymentSystem } from '@features/Payments';
import GooglePayLogo from '../../../../../public/images/gpay-logo.svg';
import PaymentButton from '../payment-button/payment-button';
export const GooglePayPaymentButton = observer(({
  subscriptionPlan,
  onLoadEnd,
  buttonContent = <GooglePayLogo />,
  ...props
}: {
  subscriptionPlan: SubscriptionPlan;
  onLoadEnd: () => void;
  buttonContent?: ReactNode;
} & Omit<ComponentProps<typeof PaymentButton>, 'children'>) => {
  const {
    paymentsStore,
    authStore
  } = useStores();
  const {
    user,
    variant
  } = authStore;
  const {
    trackFacebookEvent,
    trackPinterestEvent
  } = useAnalytics();
  let paymentSystem = PaymentSystem.BRAINTREE;
  const previousPlan: any = usePrevious({
    subscriptionPlan
  });
  const shouldFetch = useMemo(() => previousPlan ? previousPlan.subscriptionPlan !== subscriptionPlan : true, [previousPlan, subscriptionPlan]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    openCheckout
  } = usePaymentSystem({
    subscriptionPlan,
    paymentSystem,
    setIsLoading,
    onLoadEnd,
    braintreeOptions: {
      useGooglePay: true
    },
    shouldFetch: shouldFetch
  });
  const handleClick = useCallback(async () => {
    if (paymentSystem != null) {
      handleCheckoutOpenRequestWithPaymentSystem({
        subscriptionPlan,
        paymentMethod: AnalyticsPaymentMethod.GOOGLE_PAY,
        paymentSystem,
        email: user!.email,
        variant: variant
      });
      trackFacebookEvent({
        eventName: 'AddPaymentInfo',
        customData: {
          paymentSystem: 'braintree',
          paymentMethod: 'googlePay'
        },
        options: {
          email: user?.email
        }
      });
      trackPinterestEvent({
        eventName: 'AddToCart'
      });
    }
    paymentsStore.setPaymentMethod(PaymentMethod.GOOGLE_PAY);
    await openCheckout();
  }, [openCheckout, paymentSystem, paymentsStore, subscriptionPlan, trackFacebookEvent, trackPinterestEvent, user, variant]);
  return <PaymentButton onClick={handleClick} loading={isLoading} {...props} style={{
    pointerEvents: props.disabled ? 'none' : 'initial',
    opacity: props.disabled ? 0.7 : 1
  }}>
        {buttonContent}
      </PaymentButton>;
});