import { ReactElement, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { isDevelopment, noop } from 'src/utils';
import { useFacebookEvent } from '@features/Analytics/hooks/mutation/useFacebookEvent';
import { usePinterestEvent } from '@features/Analytics/hooks/mutation/usePinterestEvent';
import { useGoogleEvent } from '@features/Analytics/hooks/useGoogleEvent';
import { TpFacebookParameters, TpGoogleParameters, TpPinterestParameters } from '@features/Analytics/types';
import { useShouldInit } from './useShouldInit';
const AnalyticsDebuggerContext = createContext<{
  events: any;
  logEvent: any;
  clearEvents: () => void;
  eventsNumberVisible: boolean;
  setEventsNumberVisible: (value: boolean) => void;
}>({
  events: [],
  logEvent: () => {},
  clearEvents: () => {},
  eventsNumberVisible: false,
  setEventsNumberVisible: noop
});
export const useAnalyticsDebugger = () => useContext(AnalyticsDebuggerContext);
export const useAnalytics = () => {
  const {
    logEvent
  } = useAnalyticsDebugger();
  const {
    authStore: {
      variant,
      user
    }
  } = useStores();
  const {
    shouldPixelInit,
    shouldGoogleAnalyticsInit,
    shouldPTInit
  } = useShouldInit(variant);
  const {
    clientEvent: pinterestEvent
  } = usePinterestEvent();
  const {
    clientEvent: googleEvent
  } = useGoogleEvent();
  const {
    clientEvent: facebookEvent
  } = useFacebookEvent();
  const trackFacebookEvent = useCallback((value: TpFacebookParameters) => {
    shouldPixelInit && facebookEvent(value);
    isDevelopment && logEvent({
      name: value.eventName,
      parameters: value.options,
      source: 'FB'
    });
  }, [shouldPixelInit, facebookEvent, logEvent]);
  const trackPinterestEvent = useCallback((value: TpPinterestParameters) => {
    shouldPTInit && pinterestEvent(value);
    isDevelopment && logEvent({
      name: value.eventName,
      parameters: value.options,
      source: 'PT'
    });
  }, [shouldPTInit, pinterestEvent, logEvent]);
  const trackGoogleEvent = useCallback((value: TpGoogleParameters) => {
    const modifiedValue = Object.assign(value, {
      options: {
        email: value?.options?.email ? value?.options?.email : user?.email ?? 'missEmail',
        mentalId: user?.id ?? 'missId',
        ...value.options
      }
    });
    shouldGoogleAnalyticsInit && googleEvent(modifiedValue);
    isDevelopment && logEvent({
      name: modifiedValue.eventName,
      parameters: modifiedValue.options,
      source: 'GA'
    });
  }, [googleEvent, logEvent, shouldGoogleAnalyticsInit, user?.email, user?.id]);
  return {
    trackFacebookEvent,
    trackGoogleEvent,
    trackPinterestEvent
  };
};
export const AnalyticsDebuggerProvider = isDevelopment ? ({
  children
}: {
  children: ReactElement;
}) => {
  const [events, setEvents] = useState<{
    [key: string]: any;
  }[]>([]);
  const [eventsNumberVisible, setEventsNumberVisible] = useState(false);
  const logEvent = useCallback(({
    name,
    parameters,
    source
  }: {
    name: string;
    parameters: Record<string, any>;
    source: string;
  }) => {
    setEvents(events => [...events, {
      name,
      params: parameters,
      date: new Date(),
      source
    }]);
  }, []);
  const clearEvents = useCallback(() => {
    setEvents([]);
  }, []);
  const value = useMemo(() => ({
    events,
    logEvent,
    clearEvents,
    eventsNumberVisible,
    setEventsNumberVisible
  }), [events, logEvent, clearEvents, eventsNumberVisible, setEventsNumberVisible]);
  return <AnalyticsDebuggerContext.Provider value={value}>
          {children}
        </AnalyticsDebuggerContext.Provider>;
} : ({
  children
}: {
  children: ReactElement;
}) => children;