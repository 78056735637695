import { get, post } from 'src/api/api';
import { ENDPOINT_MICROSERVICE } from 'src/constants/api';
import { SubscriptionPlanSnapshot } from 'src/models/subscription-plan/subscription-plan';

type TpPaddleLinkResponse = {
  response: { url: string };
  success: boolean;
};

export const generatePaddlePayLink = async ({
  productId,
  customerEmail,
  token,
  userId,
  abortSignal,
}: {
  productId: string | number;
  customerEmail: string;
  token: string;
  userId: string | number;
  abortSignal?: AbortSignal;
}): Promise<TpPaddleLinkResponse> =>
  await post({
    endpoint: ENDPOINT_MICROSERVICE,
    path: 'paddle_generate_pay_link',
    body: {
      product_id: productId,
      customer_email: customerEmail,
      token: token,
      user_id: userId,
      vendor_id: Number(process.env.NEXT_PUBLIC_PADDLE_VENDOR_ID),
    },
    abortSignal,
  });

export const createChargebeeCustomer = ({ nonce }: { nonce: string }) =>
  post({
    endpoint: ENDPOINT_MICROSERVICE,
    path: 'chargebee/create_customer',
    body: {
      type: 'card',
      payment_type: 'card',
      gateway_account_id: 'gw_199Lb0Tn68WUU1Gk',
      reference_id: 'cus_I58PkwpAskxXlJ',
      tmp_token: nonce,
    },
  });

const responseToSubscriptionPlan = (
  response: any,
): SubscriptionPlanSnapshot => ({
  id: response['id'],
  name: response['name'],
  currency: response['currency'] != null ? response['currency'] : 'USD',
  priceAfterIntroOffer:
    response['price_after_intro_offer'] != null
      ? response['price_after_intro_offer']
      : response['recurring_price'],
  billingPeriod: response['billing_period'],
  billingPeriodUnit: response['billing_period_unit'],
  trialPeriodDays:
    response['trial_period_days'] != null
      ? response['trial_period_days']
      : response['trial_days'],
  specialOffer: response['special_offer'],
  discountPercentage: response['discount_percentage'],
  displayPrice: response['display_price'],
  fractionalPrice: +response['fractional_price'],
  fractionalPriceDiscount: +response['fractional_price_discount'],
  fullPrice: +response['full_price'],
  fullPriceDiscount: +response['full_price_discount'],
  initialPrice:
    response['initial_price'] != null ? +response['initial_price'] : undefined,
  group: response['group'],
  description: response['description'],
  merchantAccountId: response['merchant_account_id'] ?? undefined,
  order: response['order'],
  paddlePlanId:
    response['paddle_plan_id'] != null
      ? +response['paddle_plan_id']
      : undefined,
  paddleIternalName:
    response['paddle_internal_name'] != null
      ? response['paddle_internal_name']
      : undefined,
  paypalPlanId: response['paypal_plan_id'] ?? undefined,
  stripePriceId: response['stripe_price_id'] ?? undefined,
  nuveiPlanId:
    response['nuvei_plan_id'] != null ? +response['nuvei_plan_id'] : undefined,
  braintreePlanId:
    response['braintree_plan_id'] != null
      ? response['braintree_plan_id']
      : undefined,
  hideDescription:
    response['hide_description'] != null
      ? response['hide_description']
      : undefined,
  gift: response['gift'] != null ? response['gift'] : undefined,
  preselected:
    response['preselected'] != null ? response['preselected'] : undefined,
  introOfferData: response['intro_offer_data'] ?? undefined,
  introOffer: response['intro_offer'] ?? undefined,
});

export const fetchSubscriptionPlans = async ({
  abortSignal,
  group,
  paddle,
  countryCode,
}: {
  abortSignal?: AbortSignal;
  group?: string;
  paddle?: boolean;
  countryCode?: string;
}): Promise<SubscriptionPlanSnapshot[]> => {
  const responseObject = await get({
    endpoint: ENDPOINT_MICROSERVICE,
    path: paddle ? 'paddle_plans' : 'subscription_plans',
    abortSignal,
    params: { group: group ?? '', country_code: countryCode ?? '' },
  });
  return responseObject.map((planResponseObject: any) =>
    responseToSubscriptionPlan(planResponseObject),
  );
};

export const createBraintreeSubscription = async ({
  customerId,
  customerToken,
  merchantAccountId,
  planId,
  paymentMethodNonce,
  paymentMethodVaulted = true,
  isStripePAth,
  abortSignal,
  btIntroOfferAmount,
  btIntroOfferCurrency,
}: {
  customerId: string;
  customerToken: string;
  merchantAccountId?: string;
  planId: string;
  paymentMethodNonce: string;
  paymentMethodVaulted?: boolean;
  abortSignal?: AbortSignal;
  isStripePAth?: boolean;
  btIntroOfferAmount?: number;
  btIntroOfferCurrency?: string;
}): Promise<void> =>
  await post({
    endpoint: ENDPOINT_MICROSERVICE,
    path: isStripePAth
      ? 'braintree_create_subscription_from_stripe'
      : 'braintree_create_subscription',
    body: {
      app: 'FaceYoga',
      user_id: customerId,
      token: customerToken,
      plan_id: planId,
      payment_method_nonce: paymentMethodNonce,
      payment_method_vaulted: paymentMethodVaulted,
      merchant_account_id: merchantAccountId,
      bt_introoffer_amount: btIntroOfferAmount,
      bt_introoffer_currency: btIntroOfferCurrency,
    },
    abortSignal,
  });
