import { GA_ID } from '../constants';

export const googlePageView = (url: string) => {
  // @ts-ignore
  window.gtag?.('config', GA_ID, {
    page_path: url,
  });
};
export const googleAnalyticsEvent = ({
  eventName,
  properties,
}: {
  eventName: string;
  properties?: { [key: string]: any };
}) => {
  window.gtag?.('event', eventName, properties);
};

export const googleSetUserProperties = ({
  properties,
}: {
  properties: { [key: string]: string | number | boolean | null };
}) => window.gtag?.('set', 'user_data', properties);
