import { Stripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import React, { ReactNode, memo, useState } from 'react';
import { MappedPaymentMethodComponent } from '@features/Stripe/widgets/checkout-selector/widgets/payment-option-tab';
import styles from './compact-selector.module.scss';
type TpCompactSelectorProps = {
  paymentMethods: {
    paymentMethodName: 'PayPal' | 'Google Pay' | 'Apple Pay' | 'Credit card';
    paymentMethodLogo: ReactNode;
    paymentMethodLogoCompact: ReactNode;
    paymentMethodContentList: string[] | undefined;
    paymentButtonText: string | undefined;
  }[];
  stripe: Stripe | null;
  clientSecret: string;
  userEmail: string;
  sendAddPaymentInfoEvents: ({
    paymentMethod
  }: {
    paymentMethod: string;
  }) => void;
  handleProcessingStatus: (status: boolean) => void;
  onSuccess: ({
    paymentMethod,
    paymentSystem
  }: {
    paymentMethod: string;
    paymentSystem?: 'stripe' | 'braintree';
  }) => Promise<void>;
  planInfo: {
    priceId: string;
    planName: string;
    fullPriceDiscount: number;
    billingPeriod: number;
    billingPeriodUnit: string;
    introOffer?: string;
    currency: string;
    braintreePlanId?: string;
    braintreeMerchantId?: string;
  };
};
export const CompactSelector = memo((props: TpCompactSelectorProps) => {
  const {
    paymentMethods,
    stripe,
    clientSecret,
    userEmail,
    sendAddPaymentInfoEvents,
    handleProcessingStatus,
    onSuccess,
    planInfo
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMethod, setSelectedMethod] = useState(paymentMethods[paymentMethods.length - 1]);
  return <>
      <div className={styles.selectorTabs}>
        {paymentMethods.map(paymentMethod => <div key={paymentMethod.paymentMethodName} className={classNames(styles.paymentOptionTab, selectedMethod.paymentMethodName === paymentMethod.paymentMethodName && styles.paymentOptionTabSelected)} onClick={() => {
        setSelectedMethod(paymentMethod);
      }}>
            {paymentMethod.paymentMethodLogoCompact}
          </div>)}
      </div>

      <div className={styles.paymentOptionSubmitButton}>
        <div className={styles.buttonCoverage} style={{
        opacity: selectedMethod.paymentMethodName === 'Credit card' || isLoading ? 0 : 1,
        backgroundColor: selectedMethod.paymentMethodName === 'PayPal' ? '#F6C657' : '#000',
        color: selectedMethod.paymentMethodName === 'PayPal' ? '#000' : '#fff'
      }}>
          Continue with {selectedMethod.paymentMethodLogoCompact}
        </div>
        <div className={styles.buttonWrapper} style={{
        opacity: selectedMethod.paymentMethodName === 'Credit card' || isLoading ? 1 : 0.0001
      }}>
          <MappedPaymentMethodComponent paymentMethodName={selectedMethod.paymentMethodName} stripe={stripe} clientSecret={clientSecret} userEmail={userEmail} onLoadEnd={() => setIsLoading(false)} sendAddPaymentInfoEvents={sendAddPaymentInfoEvents} handleProcessingStatus={handleProcessingStatus} onSuccess={onSuccess} planInfo={planInfo} />
        </div>
      </div>
    </>;
});
CompactSelector.displayName = 'CompactSelector';