import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { ReactNode, RefObject, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { PAYMENT_MODAL_QUERY_PARAM } from 'src/features/Paywall/constants';
import { PaymentModalStatus } from '@features/Payments';
import Banner from '../../components/banner/banner';
import styles from './paywall-with-scroll.module.scss';
type TpPaywallWithScrollProps = {
  onDiscountTimeFinished: () => void;
  children: ReactNode;
};
const PaywallWithScroll = (props: TpPaywallWithScrollProps, ref: RefObject<HTMLElement>) => {
  const {
    onDiscountTimeFinished,
    children
  } = props;
  const paymentSectionRef = ref;
  const [isHideHeader, setHideHeader] = useState(false);
  const [isHideFooter, setHideFooter] = useState(false);
  const [initFooter, setFooter] = useState(false);
  const {
    authStore
  } = useStores();
  const router = useRouter();
  const {
    query
  } = router;
  const {
    isPaddleVariants
  } = authStore;
  const isInlinePaddleActive = query[PAYMENT_MODAL_QUERY_PARAM] === PaymentModalStatus.SHOW && isPaddleVariants;
  const onScrollToPayment = useCallback(() => {
    if (paymentSectionRef.current) {
      paymentSectionRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [paymentSectionRef]);
  const onScroll = useCallback(() => {
    if (paymentSectionRef.current) {
      const {
        top,
        bottom
      } = paymentSectionRef.current.getBoundingClientRect();
      setFooter(top <= 0);
      setHideHeader(top <= 0);
      setHideFooter(bottom >= 500);
    }
  }, [paymentSectionRef]);
  const renderBanner = useMemo(() => <Banner onClick={onScrollToPayment} onFinish={onDiscountTimeFinished} />, [onDiscountTimeFinished, onScrollToPayment]);
  useEffect(() => {
    if (query[PAYMENT_MODAL_QUERY_PARAM] === PaymentModalStatus.HIDE && isPaddleVariants) {
      onScrollToPayment();
    }
  }, [isPaddleVariants, onScrollToPayment, query]);
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);
  return <div className={styles.container} data-sentry-component="PaywallWithScroll" data-sentry-source-file="index.tsx">
      {!isInlinePaddleActive && <section className={classNames(styles.headerSection, isHideHeader && styles.hideHeader)}>
          {renderBanner}
        </section>}

      {children}

      <section className={classNames(styles.footerSection, initFooter && styles.initFooter, isHideFooter && styles.hideFooter)}>
        {renderBanner}
      </section>
    </div>;
};

// @ts-ignore
export const PaywallForwarded = observer(forwardRef(PaywallWithScroll));