import { Typography } from '@mentalgrowth/ui-kit-web';
import classNames from 'classnames';
import React, { memo } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { UiKitLoadingButton } from 'src/components/common/ui-kit-loading-button';
import { CurrentPrice } from 'src/features/Paywall/widgets/subscription-card/current-price';
import { PriceUnitFull } from 'src/features/Paywall/widgets/subscription-card/price-unit-full';
import { getDataFromSessionStorage } from 'src/utils';
import { SubscriptionCardMaster } from '@features/Paywall';
import { BillingPeriod } from '@features/Paywall/widgets/subscription-card/billing-period';
import { DiscountPercentage } from '@features/Paywall/widgets/subscription-card/discount-percentage';
import { FullPrice } from '@features/Paywall/widgets/subscription-card/full-price';
import { LearnMoreList } from '@features/Stripe/components/LearnMoreList';
import { TpStripePlanCardProps } from '@features/Stripe/types';
import { FormattedMessage } from '@features/intl';
import IconChevronDown from '../../../../../public/icons/chevron-down.svg';
import styles from './subscription-plan-card.module.scss';
export const SubscriptionPlanCardStripe = memo((props: TpStripePlanCardProps) => {
  const {
    plan,
    disabled = false,
    getPlanDisabled,
    wasPaymentTried,
    handlePlanSelect,
    planCardClassname,
    learnMoreList,
    submitButtonText
  } = props;
  const currentVariant = getDataFromSessionStorage({
    store: 'authStore',
    key: 'variant'
  });
  return <SubscriptionCardMaster {...props} planCardClassname={classNames(styles.planCard, planCardClassname)} contentClassname={styles.content} leftBottomContent={(value: boolean) => !disabled && <div className={styles.wrapper}>
              <div className={classNames(styles.text, {
      [styles.textChecked]: value
    })}>
                {plan?.fractionalPrice !== plan?.fractionalPriceDiscount && <FullPrice plan={plan} />}{' '}
                <FullPrice withDiscount plan={plan} />{' '}
                <BillingPeriod plan={plan} />
              </div>

              <div className={styles.discountPercentage}>
                {wasPaymentTried && plan.preselected && <del className={styles.defaultDiscount}>
                    {plan.specialDiscountPercentage}%
                  </del>}
                {plan?.discountPercentage !== 0 && plan?.discountPercentage != null && <DiscountPercentage variant={currentVariant === 'variant80' ? 'withText' : 'default'} discountPercentage={plan.discountPercentage} isActive={value && !wasPaymentTried} wasPaymentTried={value && wasPaymentTried} />}
              </div>
            </div>} rightContent={(value: boolean) => <div className={styles.alignEnd}>
            <div className={styles.rightBlockContainer}>
              {plan?.fractionalPrice !== plan?.fractionalPriceDiscount && <PriceUnitFull plan={plan} checked={value} className={styles.priceUnitFull} />}{' '}
              <CurrentPrice plan={plan} checked={value} />
            </div>
          </div>} fullWidthBottomContent={(value: boolean) => <div className={styles.bottomContent}>
            <div className={styles.learnMoreHeader}>
              <Typography as="p" variant="body2-bold">
                <FormattedMessage defaultMessage="Learn more" id="Onboarding.Paywall.Main.Card.Subtitle" />
              </Typography>
              <IconChevronDown className={classNames(styles.collapseIcon, {
        [styles.unCollapsed]: value
      })} />
            </div>
            {value && (plan?.learnMoreList || learnMoreList) && <>
                {plan?.learnMoreList && <LearnMoreList learnMoreList={plan.learnMoreList} />}
                {learnMoreList && <LearnMoreList learnMoreList={learnMoreList} />}
                <UiKitLoadingButton data-testid="get-my-plan" className={styles.paymentButton} fullWidth disabled={getPlanDisabled} onClick={handlePlanSelect}>
                  {submitButtonText || <FormattedMessage id="Onboarding.Paywall.Main.ActionButton" defaultMessage="Get plan" />}
                </UiKitLoadingButton>
              </>}
          </div>} />;
});
SubscriptionPlanCardStripe.displayName = 'SubscriptionPlanCardStripe';