import React from 'react';
import ReviewCard from 'src/components/common/review-card/review-card';
import type { TpReview } from 'src/components/common/reviews/reviews';
import { FormattedMessage } from '@features/intl';
import styles from './reviews.module.scss';
type Props = {
  reviews: TpReview[];
};
export const Reviews = ({
  reviews
}: Props) => <>
    <h4 className={styles.titleReview}>
      <FormattedMessage defaultMessage="Join {mark}" id="Onboarding.Paywall.Main.million.Title1" values={{
      mark: <em>
              <FormattedMessage defaultMessage="one million" id="Onboarding.Paywall.Main.million.Title2" />
            </em>
    }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />{' '}
      <FormattedMessage defaultMessage="happy users" id="Onboarding.Paywall.Main.million.Title3" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h4>

    <ul>
      {reviews.map((review, index) => <li className={styles.reviewItem} key={index}>
          <ReviewCard avatar={review.avatar} name={review.name} date={review.date} review={review.review} rating={review.rating} />
        </li>)}
    </ul>
  </>;