import React from 'react';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';
import { FormattedMessage } from '@features/intl';
import styles from './checkout-policy.module.scss';
export const CheckoutPolicy = () => <p className={styles.policy} data-sentry-component="CheckoutPolicy" data-sentry-source-file="index.tsx">
    <FormattedMessage id="Onboarding.Checkout.Terms" defaultMessage="By purchasing you agree to {terms} and {privacy}, {subscription}" values={{
    privacy: <PolicyLink place="checkout" />,
    terms: <TermsLink place="checkout" />,
    subscription: <RefundLink place="checkout" />
  }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    <br />
    <br />{' '}
    <FormattedMessage defaultMessage="Mimika will use your payment details for seemingless future payments. The transaction will be converted into USD based on your bank’s exchange rate. Subscriptions renew automatically at the end of each period unless you cancel at least 24 hours before the end of then-current period. To avoid being charged cancel your subscription by contacting our support at least 24 hours before the end of then-current period. If you are unsure how to cancel, please contact our support via support@mimika-app. You may wish to make a screenshot of this information for your reference." id="Onboarding.Checkout.Terms.Text" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
  </p>;