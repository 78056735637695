import React from 'react';
import { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';
import styles from './intro-offer-disclaimer.module.scss';
export const IntroOfferDisclaimer = ({
  subscriptionPlan
}: {
  subscriptionPlan: SubscriptionPlan;
}) => <div className={styles.disclaimer} data-sentry-component="IntroOfferDisclaimer" data-sentry-source-file="intro-offer-disclaimer.tsx">
    You agree that{' '}
    {`${subscriptionPlan.fullPriceDiscount} ${subscriptionPlan.currency}`} will
    be charged as an intro offer, and, then{' '}
    {`${subscriptionPlan.priceAfterIntroOffer} ${subscriptionPlan.currency}`}{' '}
    will be automatically billed every {subscriptionPlan.billingPeriod}{' '}
    {subscriptionPlan.billingPeriodUnit}. Cancel anytime.
  </div>;