import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/index.scss';
import '../styles/themes-tokens.css';
import '@mentalgrowth/ui-kit-web/dist/cjs/styles.css';
import * as Sentry from '@sentry/react';
import Head from "next/head";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import type { AppProps } from 'next/app';
import { ValidationError } from 'yup';
import { ResizeObserver } from '@juggle/resize-observer';
import { RootStoreProvider } from 'src/components/common/root-store-provider/root-store-provider';
import 'src/components/common/page-progress/page-progress.scss';
import { ErrorBoundary } from 'src/components/common/error-boundary/error-boundary';
import { AnalyticsInject } from '@features/Analytics';
import { IntlProvider } from '@features/intl';
import { Seo } from "src/components/common/Seo";
import { DehydratedState, HydrationBoundary, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import { AnalyticsDebuggerProvider } from "@features/Analytics/hooks/useAnalytics";
import { ThemeProvider } from "@features/Theme";
import { Inter, Merriweather } from 'next/font/google';
const PageProgress = dynamic(() => import('src/components/common/page-progress/page-progress'));
export const inter = Inter({
  weight: ['400', '700', '800'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
  variable: "--font-inter"
});
export const merriweather = Merriweather({
  weight: ['400', '700'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
  variable: "--font-merriweather"
});

// Customize yup error formatting
const formatError = ValidationError.formatError;
ValidationError.formatError = function (message, parameters) {
  if (!parameters.label) {
    parameters.label = (parameters.path + '').replace(/([a-z])([A-Z])/g, (_, match1, match2) => `${match1} ${match2.toLowerCase()}`).replace(/^[a-z]/, match => match.toUpperCase());
  }
  return formatError.call(this, message, parameters);
};

// Polyfill ResizeObserver
if (typeof window !== 'undefined' && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}
const pageDescription = 'Facial yoga & massage';
const keywords = 'face yoga, face and neck exercises, yoga facial, facial fitness, sagging jowls, how to get rid of turkey neck, face yoga exercises, facial rejuvenation, face exercises, face massage, facial exercises for cheeks, face yoga app, non-surgical facelift, best face yoga app, neck tightening exercises, facial exercises to tighten skin, get rid of wrinkles, get rid of double chin, facial exercises for jowls, yoga for double chin, chiseled jawline, home remedies for aging skin, mimika face yoga, face exercises for sagging cheeks, dark circle treatment, facial exercises, neck sagging, best way to tighten skin on face, face lifting massage, how to remove wrinkles from face quickly, Anti-Aging skin tips, facial and massage, how to get rid of face fat, Mimika, face yoga for double chin, gua sha, face tightening exercise, remove wrinkles, facial yoga';
function MyApp({
  Component,
  pageProps
}: AppProps & {
  pageProps: {
    dehydratedState: DehydratedState;
  };
}) {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30_000)
      }
    },
    queryCache: new QueryCache({
      onError: error => {
        console.log(error);
        Sentry.captureException(error);
      }
    })
  }));
  const router = useRouter();
  const canonicalUrl = (typeof window !== 'undefined' ? window.location.origin : '' + (router.asPath === "/" ? "" : router.asPath)).split("?")[0];
  return <>
        <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
          <meta name="viewport" content="initial-scale=1.0, width=device-width" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
          <meta name="description" content={pageDescription} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
          <meta name="keywords" content={keywords} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
          <link rel="canonical" href={canonicalUrl} />
        </Head>
        <style jsx global>{`
            html {
            --font-inter: ${inter.style.fontFamily};
            --font-merriweather: ${merriweather.style.fontFamily};
            --font-family-base: var(--font-inter);
            --font-family-secondary: var(--font-merriweather);
          }
        `}</style>
        <Seo canonicalUrl={canonicalUrl} data-sentry-element="Seo" data-sentry-source-file="_app.tsx" />

        <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
          <IntlProvider data-sentry-element="IntlProvider" data-sentry-source-file="_app.tsx">
            <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="_app.tsx">
              <HydrationBoundary state={pageProps.dehydratedState} data-sentry-element="HydrationBoundary" data-sentry-source-file="_app.tsx">
                <AnalyticsDebuggerProvider data-sentry-element="AnalyticsDebuggerProvider" data-sentry-source-file="_app.tsx">
                  <RootStoreProvider data-sentry-element="RootStoreProvider" data-sentry-source-file="_app.tsx">
                    <ThemeProvider data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
                      <PageProgress data-sentry-element="PageProgress" data-sentry-source-file="_app.tsx" />
                      <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                      <AnalyticsInject data-sentry-element="AnalyticsInject" data-sentry-source-file="_app.tsx" />
                    </ThemeProvider>
                  </RootStoreProvider>
                </AnalyticsDebuggerProvider>
              </HydrationBoundary>
            </ErrorBoundary>
          </IntlProvider>
        </QueryClientProvider>
      </>;
}
export default MyApp;