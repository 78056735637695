import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';
import { currencyReplacer } from '@features/Payments';
import { selectedPlanAtom } from '@features/Stripe/atoms';
import { FormattedMessage } from '@features/intl';
import styles from './AutoRenew.module.scss';
type TpAutoRenewProps = {
  placement?: 'paywall' | 'modal';
};
export const AutoRenew = (props: TpAutoRenewProps) => {
  const {
    placement = 'paywall'
  } = props;
  const {
    fullPriceDiscount,
    introOfferData,
    planName,
    currency: selectedCurrency
  } = useAtomValue(selectedPlanAtom);
  const introOfferDataMemoized = useMemo(() => ({
    fullPriceDiscount: introOfferData?.fullPriceDiscount || '0',
    introOfferDescription: introOfferData?.introOfferDescription || ''
  }), [introOfferData?.fullPriceDiscount, introOfferData?.introOfferDescription]);
  const currency = currencyReplacer(selectedCurrency) || 'USD';
  const planNameHardCoded = useMemo(() => planName?.toLowerCase() === '1 week' ? '1 month' : planName?.toLowerCase(), [planName]);
  return <div className={styles.host} data-sentry-component="AutoRenew" data-sentry-source-file="index.tsx">
      <div>
        <p>This is auto-renewing subscription.</p>
        {placement === 'paywall' ? <>
            <p>
              Today you will be automatically charged {currency}
              {introOfferDataMemoized.fullPriceDiscount} after the payment
              confirmation. The subscription will be auto-renewed every{' '}
              {planNameHardCoded} after the introductory period{' '}
              {introOfferDataMemoized.introOfferDescription} at the full price
              of {currency}
              {fullPriceDiscount}. You can cancel the subscription at any time
              at no additional cost.
            </p>
            <p>
              <FormattedMessage id="Onboarding.Paywall.Main.Terms" defaultMessage="By purchasing, you agree to our {privacy}, {terms}, {mark} {subscription}" values={{
            privacy: <PolicyLink place="paywall" />,
            terms: <TermsLink place="paywall" />,
            subscription: <RefundLink place="paywall" />,
            mark: <span>
                      <FormattedMessage defaultMessage="and" id="Onboarding.Paywall.Main.Terms.Mark" />
                    </span>
          }} />
            </p>
          </> : <p>
            Today you will be automatically charged {currency}
            {introOfferDataMemoized.fullPriceDiscount} after the payment
            confirmation. The subscription will be auto-renewed every{' '}
            {planNameHardCoded} after the introductory period{' '}
            {introOfferDataMemoized.introOfferDescription} at the full price of{' '}
            {currency}
            {fullPriceDiscount}. You can cancel the subscription at any time at
            no additional cost by contacting support@mentalgrowth.app
          </p>}
      </div>
    </div>;
};