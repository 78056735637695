import React from 'react';
import { FormattedMessage } from '@features/intl';
import LockIcon from '../../../../../../../public/icons/lock-icon-gray.svg';
import MoneyHand from '../../../../../../../public/images/money-hand.svg';
import AmexLogo from '../../../../../../assets/icons/amex.svg';
import MasterCardLogo from '../../../../../../assets/icons/mastercard.svg';
import PayPalLogo from '../../../../../../assets/icons/paypal.svg';
import UnionPayLogo from '../../../../../../assets/icons/unionpay.svg';
import VisaLogo from '../../../../../../assets/icons/visa.svg';
import styles from './guarantee-block.module.scss';
const Logos = () => <div className={styles.logos} data-sentry-component="Logos" data-sentry-source-file="index.tsx">
    <PayPalLogo width={24} data-sentry-element="PayPalLogo" data-sentry-source-file="index.tsx" />
    <VisaLogo width={62} data-sentry-element="VisaLogo" data-sentry-source-file="index.tsx" />
    <MasterCardLogo width={32} data-sentry-element="MasterCardLogo" data-sentry-source-file="index.tsx" />
    <AmexLogo width={34} data-sentry-element="AmexLogo" data-sentry-source-file="index.tsx" />
    <UnionPayLogo width={38} data-sentry-element="UnionPayLogo" data-sentry-source-file="index.tsx" />
  </div>;
export const GuaranteeBlock = ({
  showRefundRate = false
}: {
  showRefundRate?: boolean;
}) => <div className={styles.info} data-sentry-component="GuaranteeBlock" data-sentry-source-file="index.tsx">
    <h3 className={styles.guaranteeTitle}>
      <FormattedMessage id="Onboarding.Paywall.Main.SafeCheckout" defaultMessage="Guaranteed safe checkout" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h3>
    <Logos data-sentry-element="Logos" data-sentry-source-file="index.tsx" />
    <div className={styles.transactionsEncrypted}>
      <LockIcon className={styles.lockIcon} data-sentry-element="LockIcon" data-sentry-source-file="index.tsx" />
      <p>
        <FormattedMessage id="Onboarding.Paywall.Main.Encrypted" defaultMessage="All transactions are secure and encrypted" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </p>
    </div>
    {showRefundRate && <>
        <hr />
        <div className={styles.refundRate}>
          <MoneyHand className={styles.moneyHand} />
          <div className={styles.refundDescription}>
            <h3>Less than 2%</h3>
            <p>refund rate of our users</p>
          </div>
        </div>
      </>}
  </div>;