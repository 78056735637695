import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Image } from 'src/components/common/Image';
import { FormattedMessage, TpMessageKeys } from '@features/intl';
import styles from './LearnMoreList.module.scss';
type TpLearnMoreListProps = {
  isCompactStyle?: boolean;
  learnMoreList: {
    icon: string;
    titleId?: TpMessageKeys;
    titleDefaultMessage?: string;
    text?: string;
  }[];
  isChecked?: boolean;
};
export const LearnMoreList = (props: TpLearnMoreListProps) => {
  const {
    learnMoreList,
    isChecked,
    isCompactStyle = false
  } = props;
  const filteredLearnMoreList = useMemo(() => learnMoreList.filter((_, index) => isCompactStyle ? index !== 1 : true), [isCompactStyle, learnMoreList]);
  return <div className={classNames(styles.learnMoreList, {
    [styles.learnMoreListCompact]: isCompactStyle
  })} data-sentry-component="LearnMoreList" data-sentry-source-file="index.tsx">
      {filteredLearnMoreList.map(({
      icon,
      titleId,
      titleDefaultMessage,
      text
    }) => <div key={icon} className={classNames(styles.learnMoreItem, {
      [styles.learnMoreItemCompact]: isCompactStyle
    })}>
            <Image src={icon} alt="" width={isCompactStyle ? 20 : 32} height={isCompactStyle ? 20 : 32} />
            <p>
              {titleId ? <FormattedMessage defaultMessage={titleDefaultMessage ?? ''} id={titleId} /> : text}
            </p>
          </div>)}
    </div>;
};