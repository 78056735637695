import type { ModalHandle } from '@restart/ui/Modal';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import styles from './modal-container.module.scss';
const ModalContainer = ({
  className,
  backdropClassName,
  dialogClassName,
  ...props
}: ModalProps) => {
  const modalRef = useRef<ModalHandle>(null);
  return <Modal ref={modalRef} backdropClassName={classNames(styles.backdrop, backdropClassName)} className={classNames(styles.container, className)} dialogClassName={classNames(styles.modal, dialogClassName)} restoreFocusOptions={{
    preventScroll: true
  }} {...props} data-sentry-element="Modal" data-sentry-component="ModalContainer" data-sentry-source-file="modal-container.tsx" />;
};
export default ModalContainer;