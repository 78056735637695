import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CloseButton from 'src/components/common/close-button/close-button';
import Alert from 'src/components/common/modal/alert/alert';
import Modal from 'src/components/common/modal/modal/modal';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { SHOW_GOOGLE_PAY_BUTTON } from 'src/constants/features';
import { tikTokAdvancedMatching, tikTokEvent, useAnalytics } from '@features/Analytics';
import { braintreeClientTokenAtom } from '@features/Payments/atoms';
import { PAYMENT_MODAL_QUERY_PARAM } from '@features/Paywall';
import { FormattedMessage } from '@features/intl';
import LockIcon from '../../../../../public/icons/lock-icon-gray.svg';
import AmexLogo from '../../../../assets/icons/amex.svg';
import MastercardLogo from '../../../../assets/icons/mastercard.svg';
import PayPalLogo from '../../../../assets/icons/paypal.svg';
import UnionpayLogo from '../../../../assets/icons/unionpay.svg';
import VisaLogo from '../../../../assets/icons/visa.svg';
import ApplePayLogo from '../../../../assets/images/payment/apple-pay-logo.svg';
import BrowserMenuArrow from '../../../../assets/images/payment/browser-menu-arrow.svg';
import PaymentLoader from '../../../../assets/images/payment/payment-loader.svg';
import ApplePayPaymentButton, { SHOW_APPLE_PAY_BUTTON, useApplePayPaymentButton } from '../apple-pay-payment-button/apple-pay-payment-button';
import CardPaymentButton from '../card-payment-button/card-payment-button';
import { GooglePayPaymentButton } from '../google-pay-payment-button/google-pay-payment-button';
import PaypalPaymentButton from '../paypal-payment-button/paypal-payment-button';
import styles from './payment-modal.module.scss';
export enum PaymentModalStatus {
  SHOW = '1',
  HIDE = '0',
}
const PaymentModal = observer(({
  openApplePayCheckoutOnMount
}: {
  openApplePayCheckoutOnMount: boolean;
}) => {
  const {
    paymentsStore,
    authStore
  } = useStores();
  const router = useRouter();
  const {
    query,
    pathname
  } = router;
  const {
    allSubscriptionPlans,
    setWasPaymentModalClosed
  } = paymentsStore;
  const {
    user,
    variant,
    isPaddleVariants
  } = authStore;
  const {
    trackFacebookEvent,
    trackGoogleEvent
  } = useAnalytics();
  const braintreeClientToken = useAtomValue(braintreeClientTokenAtom);
  const isPaymentModalActive = query[PAYMENT_MODAL_QUERY_PARAM] === PaymentModalStatus.SHOW && !isPaddleVariants;
  const selectedPlanId = query['plan'];
  const onHide = () => {
    // noinspection JSIgnoredPromiseFromCall
    router.push({
      pathname,
      query: {
        ...query,
        [PAYMENT_MODAL_QUERY_PARAM]: PaymentModalStatus.HIDE
      }
    }, undefined, {
      shallow: true
    });
    trackGoogleEvent({
      eventName: 'initiate_checkout_skipped'
    });
  };

  // Selected subscription plan

  const subscriptionPlan = useMemo(() => selectedPlanId ? allSubscriptionPlans?.find(({
    id
  }) => id === selectedPlanId) : undefined, [allSubscriptionPlans, selectedPlanId]);

  // Apple Pay

  const {
    showSafariRedirectPrompt
  } = useApplePayPaymentButton();

  //render
  const [isPaypalLoading, setPaypalLoading] = useState(true);
  const [isApplePayLoading, setApplePayLoading] = useState(true);
  const onPaypalLoadEnd = useCallback(() => setPaypalLoading(false), []);
  const onApplePayLoadEnd = useCallback(() => setApplePayLoading(false), []);
  useEffect(() => {
    if (isPaymentModalActive && subscriptionPlan) {
      trackFacebookEvent({
        eventName: 'InitiateCheckout',
        customData: {
          paymentSystem: 'braintree'
        },
        options: {
          email: user?.email
        }
      });
      trackGoogleEvent({
        eventName: 'InitiateCheckout',
        options: {
          action: 'initiate_checkout',
          category: 'get_plan',
          label: 'InitiateCheckout',
          value: subscriptionPlan.fullPriceDiscount
        }
      });
      user?.email ? tikTokAdvancedMatching(user.email) : null;
      tikTokEvent('InitiateCheckout');
    }
  }, [isPaymentModalActive, subscriptionPlan, trackFacebookEvent, trackGoogleEvent, user?.email, variant]);
  const isLoading = isPaypalLoading || SHOW_APPLE_PAY_BUTTON && isApplePayLoading;
  const isPlanHasPriceIntroOffer = subscriptionPlan && +subscriptionPlan.priceAfterIntroOffer > 0;
  return !subscriptionPlan || !braintreeClientToken ? <div className={styles.hideLoading}></div> : <>
        <Alert hidden={showSafariRedirectPrompt} className={styles.container} backdropClassName={styles.backdrop} title="Select your payment method" footer={<footer className={styles.footer}>
              <p>Guaranteed safe checkout</p>
              <figure className={styles.logosContainer}>
                <PayPalLogo className={styles.paypalLogo} />
                <VisaLogo className={styles.visaLogo} />
                <MastercardLogo className={styles.mastercardLogo} />
                <AmexLogo className={styles.amexLogo} />
                <UnionpayLogo className={styles.unionpayLogo} />
              </figure>
              <p>
                <LockIcon className={styles.lockIcon} />
                All transactions are secure and encrypted
              </p>
            </footer>} show={isPaymentModalActive} onHide={() => {
      onHide();
      setApplePayLoading(true);
      setPaypalLoading(true);
      setWasPaymentModalClosed(true);
    }}>
          <hr className={styles.line} />
          <CloseButton className={styles.headerCloseButton} onClick={() => {
        onHide();
        setApplePayLoading(true);
        setPaypalLoading(true);
        setWasPaymentModalClosed(true);
      }} />
          <div className={classNames(styles.paymentButtonsContainer, isLoading && styles.hidePayments)}>
            <div className={styles.currentPlanInfo}>
              <div className={styles.planPriceBlock}>
                <span>
                  {subscriptionPlan.billingPeriod}{' '}
                  {subscriptionPlan.billingPeriodUnit} subscription
                </span>
                {subscriptionPlan.hasTrial ? <del>{`${subscriptionPlan.priceAfterIntroOffer} ${subscriptionPlan.currency}`}</del> : <span>
                    {isPlanHasPriceIntroOffer ? subscriptionPlan.priceAfterIntroOffer : subscriptionPlan.fullPrice}
                    {` ${subscriptionPlan.currency}`}
                  </span>}
              </div>
              {subscriptionPlan.discountPercentage !== 0 && (isPlanHasPriceIntroOffer && !subscriptionPlan.hasTrial ? <div className={styles.planDiscountBlock}>
                    <span>
                      {Math.round((+subscriptionPlan.priceAfterIntroOffer - subscriptionPlan.fullPriceDiscount) / +subscriptionPlan.priceAfterIntroOffer * 100)}
                      % intro offer discount
                    </span>
                    <span>
                      -
                      {Number(+subscriptionPlan.priceAfterIntroOffer - subscriptionPlan.fullPriceDiscount).toFixed(2)}
                      {` ${subscriptionPlan.currency}`}
                    </span>
                  </div> : subscriptionPlan.hasTrial ? <div className={styles.planDiscountBlock}>
                    <span>FREE DAY PLAN</span>
                    <span>
                      {Number(+subscriptionPlan.fractionalPriceDiscount).toFixed(2)}
                      {` ${subscriptionPlan.currency}`}
                    </span>
                  </div> : <div className={styles.planDiscountBlock}>
                    <span>
                      {Math.round((+subscriptionPlan.fullPrice - subscriptionPlan.fullPriceDiscount) / +subscriptionPlan.fullPrice * 100)}
                      % personal discount
                    </span>
                    <span>
                      -
                      {Number(+subscriptionPlan.fullPrice - subscriptionPlan.fullPriceDiscount).toFixed(2)}
                      {` ${subscriptionPlan.currency}`}
                    </span>
                  </div>)}

              <hr className={styles.currentPlanInfoLine} />
              <div className={styles.totalBlock}>
                <em>Total:</em>
                <span>
                  <em>
                    {subscriptionPlan.hasTrial ? Number(subscriptionPlan.fractionalPriceDiscount).toFixed(2) : Number(subscriptionPlan.fullPriceDiscount).toFixed(2)}
                    {` ${subscriptionPlan.currency}`}
                  </em>{' '}
                  for{' '}
                  {subscriptionPlan.hasTrial ? subscriptionPlan.trialPeriodDays : subscriptionPlan.billingPeriod}{' '}
                  {subscriptionPlan.hasTrial ? 'day' : subscriptionPlan.billingPeriodUnit}
                </span>
              </div>
            </div>
            {SHOW_APPLE_PAY_BUTTON && <ApplePayPaymentButton className={styles.paymentButton} subscriptionPlan={subscriptionPlan} openCheckoutOnMount={openApplePayCheckoutOnMount} onLoadEnd={onApplePayLoadEnd} />}
            {SHOW_GOOGLE_PAY_BUTTON && <GooglePayPaymentButton className={classNames(styles.paymentButton, styles.googlePayButton)} subscriptionPlan={subscriptionPlan} onLoadEnd={onApplePayLoadEnd} />}
            <PaypalPaymentButton className={classNames(styles.paymentButton, SHOW_GOOGLE_PAY_BUTTON && styles.paypalButton)} subscriptionPlan={subscriptionPlan} onLoadEnd={onPaypalLoadEnd} />
            <CardPaymentButton className={classNames(styles.paymentButton, SHOW_GOOGLE_PAY_BUTTON && styles.debitCardButton)} subscriptionPlan={subscriptionPlan} />
            {isPlanHasPriceIntroOffer && !subscriptionPlan.hasTrial && <div className={styles.disclaimer}>
                You agree that{' '}
                {`${subscriptionPlan.fullPriceDiscount} ${subscriptionPlan.currency}`}{' '}
                will be charged as an intro offer, and, then{' '}
                {`${subscriptionPlan.priceAfterIntroOffer} ${subscriptionPlan.currency}`}{' '}
                will be automatically billed every{' '}
                {subscriptionPlan.billingPeriod}{' '}
                {subscriptionPlan.billingPeriodUnit}. Cancel anytime.
              </div>}
            {subscriptionPlan.hasTrial && <div className={styles.disclaimer}>
                You agree that after{' '}
                {`${subscriptionPlan.trialPeriodDays} ${subscriptionPlan.trialPeriodDays > 1 ? 'days ' : 'day '}`}
                trial period you will be automatically billed{' '}
                {`${subscriptionPlan.priceAfterIntroOffer} ${subscriptionPlan.currency}`}{' '}
                every{' '}
                {`${subscriptionPlan.billingPeriod} ${subscriptionPlan.billingPeriodUnit}`}
                . Cancel anytime.
              </div>}
          </div>
          {isLoading && <div className={styles.loaderContainer}>
              <PaymentLoader className={styles.loader} />
              <FormattedMessage defaultMessage="Loading payment options…" id="Onboarding.Payment.Loader.Text" />
            </div>}
          <div id="test" />
        </Alert>
        {showSafariRedirectPrompt && <Modal backdropClassName={styles.backdrop} show={showSafariRedirectPrompt} showCloseButton={false}>
            <div className={styles.safariRedirectPrompt}>
              <figure className={styles.safariRedirectPromptGraphic}>
                <BrowserMenuArrow />
                <figcaption>Open in Safari</figcaption>
              </figure>
              <div className={styles.safariRedirectPromptDetails}>
                <h2>
                  <ApplePayLogo /> is only available
                  <br /> on the Safari web browser
                </h2>
                <p>
                  To use Apple Pay, tap ••• and open this page in the Safari
                  browser
                </p>
              </div>
              <p className={styles.safariRedirectPromptBackHint}>
                You can turn back and choose another payment method
              </p>
            </div>
          </Modal>}
      </>;
});
export default PaymentModal;