import React from 'react';
import { Image } from 'src/components/common/Image';
import { getImageEsp } from 'src/utils/get-image-esp';
import { FormattedMessage, TpMessageKeys } from '@features/intl';
import styles from './graph.module.scss';
type TpStates = {
  textId: TpMessageKeys;
  defaultTextMessage: string;
  markId: TpMessageKeys;
  defaultMarkMessage: string;
};
const STATES_DATA: TpStates[] = [{
  textId: 'Onboarding.Paywall.Main.Younger.Text1',
  defaultTextMessage: 'Laboratory research has proven that Face Massage can change your {mark}',
  markId: 'Onboarding.Paywall.Main.Younger.Text.Mark',
  defaultMarkMessage: 'the intra-facial structure.'
}, {
  textId: 'Onboarding.Paywall.Main.Younger.Text2',
  defaultTextMessage: "That's why Face Yoga is a {mark}",
  markId: 'Onboarding.Paywall.Main.Younger.Text2.Mark',
  defaultMarkMessage: 'natural facelift!'
}, {
  textId: 'Onboarding.Paywall.Main.Younger.Text3',
  defaultTextMessage: 'It’s simply magical: increased blood flow to the area boosts collagen production,  {br} {mark}',
  markId: 'Onboarding.Paywall.Main.Younger.Text3.Mark',
  defaultMarkMessage: 'helping you look 5+ years younger.'
}];
export const Graph = () => <div className={styles.container} data-sentry-component="Graph" data-sentry-source-file="index.tsx">
    <h2>
      <FormattedMessage id="Onboarding.Paywall.Main.Younger.Title" defaultMessage="Look 5+ years younger {br} in just a few weeks" values={{
      br: <br />
    }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h2>

    <div className={styles.imageWrapper}>
      <Image className={styles.image} src={`graph-week-offer${getImageEsp()}.webp`} width={472} height={242} alt="Graph week" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      <Image className={styles.imageMobile} src={`graph-week-small-offer${getImageEsp()}.webp`} width={325} height={241} alt="Graph week on mobile" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    </div>
    <h3>
      <FormattedMessage defaultMessage="Did you know?" id="Onboarding.Paywall.Main.Younger.Second.Title" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h3>

    {STATES_DATA.map(({
    textId,
    defaultTextMessage,
    markId,
    defaultMarkMessage
  }) => <p key={textId}>
          <FormattedMessage id={textId} defaultMessage={defaultTextMessage} values={{
      mark: <strong>
                  <FormattedMessage id={markId} defaultMessage={defaultMarkMessage} />
                </strong>,
      br: <br />
    }} />
        </p>)}
  </div>;