import classNames from 'classnames';
import React from 'react';
import { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';
import styles from './trials-disclaimer.module.scss';
export const TrialsDisclaimer = ({
  subscriptionPlan,
  className
}: {
  subscriptionPlan: SubscriptionPlan;
  className?: string;
}) => <p className={classNames(styles.trialsDisclaimer, className)} data-sentry-component="TrialsDisclaimer" data-sentry-source-file="trials-disclaimer.tsx">
    Please note that your subscription will be automatically renewed at the full
    price of{' '}
    {`${subscriptionPlan.priceAfterIntroOffer} ${subscriptionPlan.currency}`}{' '}
    per{' '}
    {`${subscriptionPlan.billingPeriod} ${subscriptionPlan.billingPeriodUnit}`}{' '}
    at the end of the trial period.
  </p>;