import braintreeDropin, { Dropin } from 'braintree-web-drop-in';
import { observer } from 'mobx-react-lite';
import React, { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'src/components/common/modal/modal/modal';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitLoadingButton } from 'src/components/common/ui-kit-loading-button';
import { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';
import { useAnalytics } from '@features/Analytics';
import { AnalyticsPaymentMethod } from '@features/Payments';
import Lock from '../../../../assets/icons/lock.svg';
import styles from './braintree-modal.module.scss';
export const BRAINTREE_CONTAINER_ID = 'braintree-dropin-container';
const BraintreeModal = observer(({
  subscriptionPlan,
  clientToken,
  onCheckoutComplete,
  ...props
}: {
  subscriptionPlan: SubscriptionPlan;
  clientToken?: string;
  onCheckoutComplete: (paymentMethodNonce: string) => void;
} & ComponentProps<typeof Modal>) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    show
  } = props;
  const dropin = useRef<Dropin | undefined>();
  useEffect(() => () => {
    dropin.current?.teardown();
    dropin.current = undefined;
  }, [show]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    if (show) {
      if (clientToken != null && !dropin.current) {
        (async () => {
          setIsLoading(true);
          try {
            dropin.current = await braintreeDropin.create({
              authorization: clientToken,
              container: `#${BRAINTREE_CONTAINER_ID}`,
              threeDSecure: {
                amount: subscriptionPlan.immediatePrice.toFixed(2)
              }
            });
            dropin.current.on('paymentMethodRequestable', () => {
              setIsFormValid(true);
            });
            dropin.current.on('noPaymentMethodRequestable', () => {
              setIsFormValid(false);
            });
            if (dropin.current.isPaymentMethodRequestable()) {
              setIsFormValid(true);
            }
          } finally {
            setIsLoading(false);
          }
        })();
      } else {
        setIsLoading(false);
      }
    }
  }, [clientToken, show, subscriptionPlan.immediatePrice]);
  const {
    authStore
  } = useStores();
  const {
    user
  } = authStore;
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        nonce
      } = await dropin.current!.requestPaymentMethod({
        threeDSecure: {
          amount: subscriptionPlan.immediatePrice.toFixed(2),
          email: user!.email
        }
      });
      onCheckoutComplete(nonce);
      trackGoogleEvent({
        eventName: 'add_payment_info_chosen',
        options: {
          payment_system: 'braintree',
          payment_method: AnalyticsPaymentMethod.CARD
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }, [onCheckoutComplete, subscriptionPlan.immediatePrice, trackGoogleEvent, user]);
  const handleCloseModal = useCallback(() => {
    trackGoogleEvent({
      eventName: 'add_payment_info_skipped',
      options: {
        payment_system: 'braintree',
        payment_method: AnalyticsPaymentMethod.CARD
      }
    });
  }, [trackGoogleEvent]);
  return <Modal title="Debit / credit card" handleCloseModal={handleCloseModal} footer={<>
            <UiKitLoadingButton data-testid="braintree-submit-button" startIcon={<Lock />} startIconClassName={styles.submitButtonIcon} fullWidth disabled={!isFormValid} onClick={handleSubmit} loading={isLoading}>
              Proceed
            </UiKitLoadingButton>

            <p className={styles.planDescription}>
              {!subscriptionPlan.hasTrial && <>
                  Total price:
                  <b>
                    {` ${Number(subscriptionPlan.fullPriceDiscount).toFixed(2)} ${subscriptionPlan.currency}`}{' '}
                  </b>
                </>}
              {!subscriptionPlan.hasTrial ? `charged for ${subscriptionPlan.billingPeriod} ${subscriptionPlan.billingPeriodUnit}${subscriptionPlan.billingPeriod === 1 ? '' : 's'}` : <>
                  {' '}
                  You agree that after{' '}
                  {`${subscriptionPlan.trialPeriodDays} ${subscriptionPlan.trialPeriodDays > 1 ? 'days ' : 'day '}`}
                  trial period you will be automatically billed{' '}
                  {`${subscriptionPlan.priceAfterIntroOffer} ${subscriptionPlan.currency}`}{' '}
                  every{' '}
                  {`${subscriptionPlan.billingPeriod} ${subscriptionPlan.billingPeriodUnit}`}
                  . Cancel anytime.
                </>}
            </p>
          </>} {...props}>
        <div id={BRAINTREE_CONTAINER_ID} />
      </Modal>;
});
export default BraintreeModal;