import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { FormattedMessage } from 'src/features/intl';
import { VIDEO_SOURCE } from 'src/utils/constants';
import styles from './start-block.module.scss';
export const StartBlock = observer(() => {
  const {
    authStore: {
      isTwitterSource
    }
  } = useStores();
  const currentContent = useMemo(() => {
    if (isTwitterSource) {
      return <div className={styles.imageWrapper}>
          <Image className={styles.image} src="paywall-face.webp" priority width={375} height={280} layout="fill" alt="slide picture" />
        </div>;
    }
    return <video autoPlay playsInline muted loop className={styles.video}>
        <source src={`${VIDEO_SOURCE}fy-paywall.mp4`} type="video/mp4" />
      </video>;
  }, [isTwitterSource]);
  return <section className={styles.startBlockSection}>
      <h2 className={styles.titleBlock}>
        <FormattedMessage defaultMessage="Your personal {br} program is ready" id="Onboarding.Paywall.Main.Title" values={{
        br: <br />
      }} />
      </h2>
      <p className={styles.description}>
        <FormattedMessage defaultMessage="90% of Mimika users first notice changes after just one week of exercise." id="Onboarding.Paywall.Main.Subtitle" />
      </p>
      {currentContent}
    </section>;
});