import Cookies from 'js-cookie';

import { axiosMicroservice } from 'src/api/axios';
import { TEST_EVENT_CODE } from 'src/constants/api';
import { getDataFromSessionStorage } from 'src/utils';

import type { TpFacebookPostParametersV2 } from '@features/Analytics/types/facebook';

export const postFacebookEventV2 = async ({
  externalId,
  eventId,
  eventName,
  fbp,
  options,
  customData,
}: TpFacebookPostParametersV2 & { fbp: string }) => {
  const fbc = Cookies.get('_fbc');

  const pixelVariants: Record<string, string> = {
    '313267054006349': 'mimika',
    '1386433265600406': 'mimika_variant17',
    '919317399289097': 'mimika_web_v18',
    '1611927066420221': 'mimika_cpm_test',
  };

  const currentPixelIds = getDataFromSessionStorage({
    store: 'analyticsStore',
    key: 'fbPixelId',
  }) as unknown as string[];

  if (!currentPixelIds) {
    console.log('No pixel IDs found in session storage.');
    return [];
  }

  try {
    const requests = currentPixelIds.map(async (pixelId) => {
      const origin = pixelVariants[pixelId] || pixelVariants['313267054006349'];

      const { data } = await axiosMicroservice.post('fb_event', {
        fbp,
        fbc,
        event_name: eventName,
        event_id: eventId,
        external_id: externalId,
        test_event_code: TEST_EVENT_CODE,
        origin,
        ...options,
        custom_data: { ...customData },
      });
      return data;
    });

    return await Promise.all(requests);
  } catch (error) {
    console.error('Error in sending Facebook events:', error);
    return [];
  }
};
