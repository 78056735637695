import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { Image } from 'src/components/common/Image';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { routes } from 'src/constants/navigation';
import { useProgressSteps } from 'src/utils';
import { useAnalytics } from '@features/Analytics';
import styles from './FirstScreenLoading.module.scss';
const injectStyles = `
  html {
    min-height: 100%;
  }
  body {
    display: block;
  }
  body,
  #__next {
    flex: 1;
  }
  html,
  body,
  #__next {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: 100%;
  }
 `;
const peopleArray = Array.from({
  length: 9
}, (_, index) => index + 1);
const FirstScreenLoading = ({
  setGoToQuiz
}: {
  setGoToQuiz: (goToQuiz: boolean) => void;
}) => {
  const [isAnimationFinished, setAnimationFinished] = useState(false);
  const router = useRouter();
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    progress
  } = useProgressSteps({
    started: true,
    progressDurations: [500, 2000, 200, 2000, 1300],
    steps: [20, 50, 50, 80, 100]
  });
  const updateAnimationShowState = useCallback(async (newState: boolean) => {
    await new Promise(resolve => setTimeout(resolve, 6000));
    setAnimationFinished(newState);
  }, [setAnimationFinished]);
  const goToQuizHandle = useCallback(() => {
    setGoToQuiz(true);
  }, [setGoToQuiz]);
  useEffect(() => {
    trackGoogleEvent({
      eventName: 'onboarding_loader_opened'
    });
    router.prefetch(routes.quiz).then(r => r);
    updateAnimationShowState(true).then(r => r);
  }, [router, trackGoogleEvent, updateAnimationShowState]);
  return <>
      <style>{injectStyles}</style>
      <div className={styles.host} style={{
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F6F4F0',
      padding: '16px 24px 32px',
      color: '#302C28',
      height: '100%',
      overflow: 'auto'
    }}>
        <div style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '435px',
        margin: '0 auto'
      }} className={styles.content}>
          <figure className={styles.leaves}>
            <Image src="first-screen-loader-leaves.svg" alt="leaves" width={198} height={53} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
          </figure>
          <div className={styles.peopleContainer} style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          marginBottom: '32px',
          gridGap: '8px',
          justifyItems: 'center'
        }}>
            {peopleArray.slice(0, window.innerHeight < 633 ? 6 : peopleArray.length).map((person, index) => <div key={person} className={styles.people} style={{
            background: "url('/images/first-screen-loader-sprite.webp') no-repeat",
            backgroundSize: '964px',
            backgroundPositionX: `-${index * 108}px`,
            aspectRatio: 1,
            width: '100px'
          }} />)}
          </div>
          <h1 className={styles.title}>
            Feel lighter, look brighter with Mimika’s facial routine!
          </h1>
          <p className={styles.description}>
            Select your goal to see how fast you can hit it:
          </p>

          {isAnimationFinished ? <div className={styles.bottomContainer} id="options-container">
              <UiKitButton fullWidth onClick={() => goToQuizHandle()} className={styles.button}>
                Slow down aging
              </UiKitButton>
              <UiKitButton fullWidth onClick={() => goToQuizHandle()} className={styles.button}>
                Improve overall appearance
              </UiKitButton>
            </div> : <div className={styles.bottomContainer}>
              <p className={styles.additionalBlock}>
                <span className={styles.loader} />
                Loading the quiz...
              </p>
              <div className={styles.progressContainer}>
                <div className={styles.progressFill} style={{
              width: `${progress}%`
            }} />
                <span className={styles.progressTitle}>
                  {progress.toFixed(0)}%
                </span>
              </div>
            </div>}
        </div>
      </div>
    </>;
};
export default FirstScreenLoading;