import React from 'react';
import { UiKitAccordion } from 'src/components/common/ui-kit-accordion';
import { FormattedMessage, TpMessageKeys } from 'src/features/intl';
import ChevronBottom from '../../../../assets/icons/chevron-bottom.svg';
import styles from './faq.module.scss';
type TpQuestion = {
  title: {
    id: TpMessageKeys;
    defaultMessage: string;
  };
  text: {
    id: TpMessageKeys;
    defaultMessage: string;
  };
};
type TpQuestions = TpQuestion[];
const QUESTIONS: TpQuestions = [{
  title: {
    id: 'Onboarding.Paywall.Main.Question.Bullet1',
    defaultMessage: 'What is Face Yoga?'
  },
  text: {
    id: 'Onboarding.Paywall.Main.Question.Bullet1.Text',
    defaultMessage: 'Face yoga is a non-invasive practice with science-proved effectiveness. It includes gentle massage and facial exercises that will stimulate your muscles, skin, and lymphatic system.'
  }
}, {
  title: {
    id: 'Onboarding.Paywall.Main.Question.Bullet2',
    defaultMessage: 'What happens after I order?'
  },
  text: {
    id: 'Onboarding.Paywall.Main.Question.Bullet2.Text',
    defaultMessage: "You'll notice some immediate changes! During the first week, your face will be tighter and slimmer; but the sky's the limit! Be patient and appreciate your day-by-day transformation."
  }
}, {
  title: {
    id: 'Onboarding.Paywall.Main.Question.Bullet3',
    defaultMessage: 'When will I receive my plan?'
  },
  text: {
    id: 'Onboarding.Paywall.Main.Question.Bullet3.Text',
    defaultMessage: 'All done! Your responses will help us create your personal face yoga program!'
  }
}];
export const Faq = () => <>
    <h3 className={styles.titleFaq}>
      <FormattedMessage id="Onboarding.Paywall.Main.Question.Title" defaultMessage="Got questions?" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </h3>
    <p>
      <FormattedMessage id="Onboarding.Paywall.Main.Question.Subtitle" defaultMessage="We’ve got you covered." data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </p>
    <UiKitAccordion alwaysOpen defaultActiveKey={3} className={styles.accordion} data-sentry-element="UiKitAccordion" data-sentry-source-file="index.tsx">
      {QUESTIONS.map((question, index) => {
      const {
        title,
        text
      } = question;
      return <UiKitAccordion.Item key={index} id={index} className={styles.question} classNameOpened={styles.classNameOpened}>
            <UiKitAccordion.Toggle id={index}>
              <div className={styles.header}>
                <h5 className={styles.accordionTitle}>
                  <FormattedMessage defaultMessage={title.defaultMessage} id={title.id} />
                </h5>
                <div className={styles.icon}>
                  <ChevronBottom />
                </div>
              </div>
            </UiKitAccordion.Toggle>
            <UiKitAccordion.CollapsibleBody id={index}>
              <p>
                <FormattedMessage defaultMessage={text.defaultMessage} id={text.id} />
              </p>
            </UiKitAccordion.CollapsibleBody>
          </UiKitAccordion.Item>;
    })}
    </UiKitAccordion>
  </>;