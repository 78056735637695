import { useAtomValue } from 'jotai';
import React, { useCallback, useContext, useEffect } from 'react';
import { Image } from 'src/components/common/Image';
import Alert from 'src/components/common/modal/alert/alert';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { getImageEsp } from 'src/utils/get-image-esp';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';
import { useAnalytics } from '@features/Analytics';
import { currencyReplacer } from '@features/Payments';
import { paymentMethodWithPersistence, upsellInfoAtom, userAtom } from '@features/Stripe/atoms';
import { useCreateInvoice } from '@features/Stripe/hooks/mutation/use-create-invoice';
import { ModalContext } from '@features/Stripe/widgets/payment-plans-stripe';
import { FormattedMessage } from '@features/intl';
import styles from './modal-upsell.module.scss';
type TpModalUpsellProps = {
  isShow: boolean;
};
export const ModalUpsell = (props: TpModalUpsellProps) => {
  const {
    isShow
  } = props;
  const {
    customerId,
    userId
  } = useAtomValue(userAtom);
  const paymentMethod = useAtomValue(paymentMethodWithPersistence);
  const isPayedWithBraintree = paymentMethod === 'paypal';
  const {
    mutateAsync: createInvoice
  } = useCreateInvoice();
  const {
    currency,
    fullPrice,
    fullPriceDiscount
  } = useAtomValue(upsellInfoAtom);
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    modalDispatch
  } = useContext(ModalContext);
  const handleBuy = useCallback(() => {
    createInvoice({
      customerId: isPayedWithBraintree ? userId.toString() : customerId,
      paymentSystem: isPayedWithBraintree ? 'braintree' : 'stripe',
      amount: fullPriceDiscount.toString(),
      currency: isPayedWithBraintree ? currency : undefined
    }).then(r => r);
    modalDispatch({
      type: 'SHOW_MODAL_SUCCEED'
    });
    trackGoogleEvent({
      eventName: 'upsale_chose'
    });
  }, [createInvoice, isPayedWithBraintree, userId, customerId, fullPriceDiscount, currency, modalDispatch, trackGoogleEvent]);
  const handleDecline = useCallback(() => {
    modalDispatch({
      type: 'SHOW_MODAL_SUCCEED'
    });
    trackGoogleEvent({
      eventName: 'upsale_decline'
    });
  }, [modalDispatch, trackGoogleEvent]);
  useEffect(() => {
    if (isShow) {
      trackGoogleEvent({
        eventName: 'upsale_open'
      });
    }
  }, [isShow, trackGoogleEvent]);
  return <Alert className={styles.container} show={isShow} data-sentry-element="Alert" data-sentry-component="ModalUpsell" data-sentry-source-file="index.tsx">
      <div className={styles.fullviewportContainer} style={{
      minHeight: `${window.innerHeight - 4}px`
    }}>
        <div className={styles.imageWrapper}>
          <Image src={`ebook${getImageEsp()}.webp`} alt="Mimika ebook" layout="fill" objectFit="contain" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </div>
        <h2 className={styles.title}>
          <FormattedMessage id="Onboarding.Book.Title" defaultMessage="Get exclusive eBook for {br} {mark}" values={{
          mark: <mark>
                  <FormattedMessage defaultMessage="better results" id="Onboarding.Book.Title.Mark" />
                </mark>,
          br: <br />
        }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        </h2>

        <ul className={styles.ebookFeatures}>
          <li>
            <FormattedMessage id="Onboarding.Book.Bullet1" defaultMessage="Topics range from healthy nutrition to skin care" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
          </li>
          <li>
            <FormattedMessage id="Onboarding.Book.Bullet2" defaultMessage="Additional checklists, trackers and more" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
          </li>
          <li>
            <FormattedMessage id="Onboarding.Book.Bullet3" defaultMessage="Emotional well-being guides" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
          </li>
          <li>
            <b>
              <FormattedMessage id="Onboarding.Book.Bullet4" defaultMessage="Lifetime access" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
            </b>
          </li>
        </ul>

        <div className={styles.price}>
          <del>{`${currencyReplacer(currency)} ${fullPrice}`}</del>
          <b>{`${currencyReplacer(currency)} ${fullPriceDiscount}`}</b>
        </div>

        <UiKitButton fullWidth className={styles.payButton} onClick={handleBuy} data-testid="upsale-button" data-sentry-element="UiKitButton" data-sentry-source-file="index.tsx">
          <FormattedMessage id="Onboarding.Book.Button" defaultMessage="Get access" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        </UiKitButton>

        <UiKitButton as="a" color="pure" className={styles.declineButton} onClick={handleDecline} data-testid="upsale-button-decline" data-sentry-element="UiKitButton" data-sentry-source-file="index.tsx">
          <FormattedMessage id="Onboarding.Book.Button.Decline" defaultMessage="Decline an offer" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        </UiKitButton>

        <p className={styles.policyFirstLine}>
          <FormattedMessage id="Onboarding.Book.Terms" defaultMessage="By purchasing, you agree to our {privacy}, {terms}, {mark} {subscription}" values={{
          terms: <TermsLink place="upsale" />,
          privacy: <PolicyLink place="upsale" />,
          subscription: <RefundLink place="upsale" />,
          br: <br />,
          mark: <mark className={styles.policyFirstLine}>
                  <FormattedMessage id="Onboarding.Book.Terms.Mark" defaultMessage="and" />
                </mark>
        }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        </p>
      </div>

      <br />
      <p className={styles.underScrollText}>
        <FormattedMessage id="Onboarding.Book.Text" defaultMessage="Mimika will use your payment details for seamless future payments. The transaction will be converted into USD based on your bank’s exchange rate. This is a one-time payment offer with the life-time access, and this purchase is not eligible for a refund. If you cannot get the access to the eBook, please reach us out at support@mimika-app. You may wish to make a screenshot of this information for your reference." data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </p>
    </Alert>;
};