import classNames from 'classnames';
import React, { MouseEvent, useCallback, useState } from 'react';
import styles from './simple-slider.module.scss';
const isInViewport = (left: number, right: number) => left >= 0 && right <= (window.innerWidth || document.documentElement.clientWidth);
type TpSimpleSliderProps = {
  slides: any[]; // Array of slides
  slideWidth: number; // Width of 1 slide
  slidesGap: number; // Gap between slides
  containerClassName: string; // Custom styles for container
};

export const SimpleSlider = ({
  slides,
  slideWidth = 136,
  slidesGap = 16,
  containerClassName
}: TpSimpleSliderProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onScroll = useCallback((event: MouseEvent<HTMLUListElement>) => {
    const parentRect = event.currentTarget.getBoundingClientRect();
    const firstRect = event.currentTarget.children[0].getBoundingClientRect();
    const lastRect = event.currentTarget.children[event.currentTarget.children.length - 1].getBoundingClientRect();
    const isLeftSlideEnd = parentRect.left <= firstRect.left;
    const isRightSlideEnd = parentRect.right >= lastRect.right - 3;
    for (const li of event.currentTarget.children) {
      const {
        left,
        right
      } = li.getBoundingClientRect();
      if (isLeftSlideEnd) {
        return setActiveIndex(0);
      }
      if (isRightSlideEnd) {
        return setActiveIndex(event.currentTarget.children.length - 1);
      }
      if (isInViewport(left, right)) {
        return setActiveIndex((li as HTMLLIElement).value);
      }
    }
  }, []);
  const onClick = useCallback((event: MouseEvent<HTMLLIElement>) => {
    event.currentTarget.scrollIntoView({
      inline: 'center',
      block: 'nearest',
      behavior: 'smooth'
    });
    setActiveIndex(event.currentTarget.value);
  }, []);
  return <div data-sentry-component="SimpleSlider" data-sentry-source-file="index.tsx">
      <ul className={classNames(styles.photosList, containerClassName)} onScroll={onScroll} style={{
      gridGap: slidesGap
    }}>
        {slides.map((slide, index) => <li style={{
        width: slideWidth,
        minWidth: slideWidth
      }} onClick={onClick} className={styles.photoItem} key={index} value={index}>
            {slide}
          </li>)}
      </ul>
      <div className={styles.pagination}>
        {Array.from({
        length: slides.length
      }, (_, index) => <button className={classNames(styles.paginationButton, activeIndex === index && styles.paginationButtonActive)} key={index} />)}
      </div>
    </div>;
};