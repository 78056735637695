import { observer } from 'mobx-react-lite';
import React from 'react';
import { CircularVideoPlayer } from 'src/components/common/circular-video-player';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { SimpleSlider } from 'src/components/common/simple-slider';
import { VIDEO_REVIEWS_TEST_NAME, VideoReviewsTestCaseName } from 'src/constants/analytics';
import { VIDEO_SOURCE } from 'src/utils/constants';
import { FormattedMessage } from '@features/intl';
import styles from './feel-younger.module.scss';
const VIDEO_REVIEWS = [{
  src: `${VIDEO_SOURCE}mimika_review1.mp4`
}, {
  src: `${VIDEO_SOURCE}mimika_review2.mp4`
}, {
  src: `${VIDEO_SOURCE}mimika_review3.mp4`
}, {
  src: `${VIDEO_SOURCE}mimika_review4.mp4`
}];
export const FeelYounger = observer(() => {
  const {
    analyticsStore: {
      getCaseNameByTestName
    }
  } = useStores();
  const isVideoReviewTest = getCaseNameByTestName(VIDEO_REVIEWS_TEST_NAME) === VideoReviewsTestCaseName.SHOW;
  return <section className={styles.feelYoungerSection}>
      <h2>
        <FormattedMessage id="Onboarding.Paywall.Main.92%" defaultMessage="{mark} say they start to look & feel younger" values={{
        mark: <mark>
                <FormattedMessage defaultMessage="92% of our users" id="Onboarding.Paywall.Main.92%.Mark" />
              </mark>
      }} />
      </h2>

      {isVideoReviewTest && <SimpleSlider slidesGap={16} slideWidth={136} containerClassName={styles.videoReviewsContainer} slides={VIDEO_REVIEWS.map(({
      src
    }) => <CircularVideoPlayer key={src} id={src} src={src} size={136} strokeWidth={4} autoPlay={false} muted={false} loop={false} />)} />}
    </section>;
});