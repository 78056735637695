import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useMemo, useRef } from 'react';
import { reviews } from 'src/components/common/reviews/reviews';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { PRESELECTED_SUBSCRIPTION_TEST_NAME, PreselectedSubscriptionTestCaseName, UPGRADE_FIRST_AB_TEST_NAME, UpgradeFirstAbTestCaseName } from 'src/constants/analytics';
import type { TpStripeVariants } from 'src/constants/variants';
import { Policy } from 'src/widgets/policy';
import { PaymentModalStatus } from '@features/Payments';
import { Answers } from '@features/Paywall/components/answers';
import { Community } from '@features/Paywall/components/community';
import { Compare } from '@features/Paywall/components/compare';
import { ContactBlock } from '@features/Paywall/components/contact-block';
import { Faq } from '@features/Paywall/components/faq';
import { FeelYounger } from '@features/Paywall/components/feel-younger';
import { Graph } from '@features/Paywall/components/graph';
import { Guarantees } from '@features/Paywall/components/guarantees';
import { PressSection } from '@features/Paywall/components/press-section';
import { Reviews } from '@features/Paywall/components/reviews';
import { StartBlockTransactionDeclined } from '@features/Paywall/components/start-block-transaktion-declined';
import { StartBlock } from '@features/Paywall/components/start-block/start-block';
import { PAYMENT_MODAL_QUERY_PARAM } from '@features/Paywall/constants';
import { PaywallForwarded } from '@features/Paywall/hocs/paywall-with-scroll';
import { PaymentPlans } from '@features/Paywall/widgets/payment-plans';
import { PaymentPlansStripe } from '@features/Stripe';
import { stripePaymentErrorAtom } from '@features/Stripe/atoms';
import styles from './long-paywall.module.scss';
const REVIEWS_BLOCK_1 = [reviews.user8, reviews.user7, reviews.user9];
export const LongPaywall = observer(({
  planGroup,
  onDiscountTimeFinished
}: {
  planGroup: string | undefined;
  onDiscountTimeFinished: () => void;
}) => {
  const paymentSectionRef = useRef<HTMLElement | null>(null);
  const {
    wasErrorModalWithOfferShown
  } = useAtomValue(stripePaymentErrorAtom);
  const {
    authStore,
    analyticsStore: {
      getCaseNameByTestName
    }
  } = useStores();
  const {
    variant,
    isPaddleVariants,
    user,
    access_code,
    isStripeVariant
  } = authStore;
  const router = useRouter();
  const {
    query
  } = router;
  const isUpgradeFirstABTest = getCaseNameByTestName(UPGRADE_FIRST_AB_TEST_NAME) === UpgradeFirstAbTestCaseName.NEW;
  const isPreselectedSubscriptionABTest = getCaseNameByTestName(PRESELECTED_SUBSCRIPTION_TEST_NAME) === PreselectedSubscriptionTestCaseName.NEW;
  const isInlinePaddleActive = query[PAYMENT_MODAL_QUERY_PARAM] === PaymentModalStatus.SHOW && isPaddleVariants;

  //This is only for a/b tests with planGroups
  const mapHardcodedVariantForProductId = useMemo(() => {
    if (isUpgradeFirstABTest) {
      return 'variant16-ab416';
    } else if (isPreselectedSubscriptionABTest) {
      return 'variant21-DEV885';
    }
    return variant;
  }, [isPreselectedSubscriptionABTest, isUpgradeFirstABTest, variant]);
  const renderPaymentPlans = useMemo(() => {
    if (isStripeVariant && user) {
      const userProperty = {
        user,
        accessCode: access_code ?? ''
      };
      return <PaymentPlansStripe replaceVariantWithHardCodeTestValue={(mapHardcodedVariantForProductId as TpStripeVariants)} variant={(variant as TpStripeVariants)} userProperty={userProperty} />;
    }
    return <PaymentPlans isInlinePaddlePaymentActive={isInlinePaddleActive} showPolicyAgreement={!isPaddleVariants} isGuaranteeBlock={!isPaddleVariants} subscriptionPlanGroup={planGroup} />;
  }, [isStripeVariant, user, isInlinePaddleActive, isPaddleVariants, planGroup, access_code, mapHardcodedVariantForProductId, variant]);
  return <PaywallForwarded onDiscountTimeFinished={onDiscountTimeFinished} ref={paymentSectionRef}>
        {!wasErrorModalWithOfferShown && !isInlinePaddleActive && variant !== 'email2' && <StartBlock />}
        {(wasErrorModalWithOfferShown || variant === 'email2') && <StartBlockTransactionDeclined variant={variant} />}
        <section id="paymentSection" ref={paymentSectionRef} className={classNames(styles.paymentSection)}>
          {renderPaymentPlans}
        </section>
        <section>
          <PressSection />
        </section>
        <section>
          <Compare />
        </section>
        <FeelYounger />

        {!wasErrorModalWithOfferShown && <>
            <section>
              <Answers />
            </section>
            <section>
              <Graph />
            </section>
          </>}
        <section>
          <Reviews reviews={REVIEWS_BLOCK_1} />
        </section>
        <section>
          <Community />
        </section>
        <section>
          <Faq />
        </section>
        <section>
          <Guarantees />
        </section>

        <section>
          <ContactBlock />
        </section>
        <div className={styles.policyWrapper}>
          <Policy className={styles.policy} place="paywall" />
        </div>
      </PaywallForwarded>;
});