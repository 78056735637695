import confetti from 'canvas-confetti';
import { useAtomValue } from 'jotai';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Alert from 'src/components/common/modal/alert/alert';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { useAnalytics } from '@features/Analytics';
import { ContactFormModal } from '@features/Paywall/components/contact-form-modal';
import { stripeWasPaymentTriedAtom, userAtom } from '@features/Stripe/atoms';
import { FormattedMessage } from '@features/intl';
import CheckMark from '../../../../assets/images/payment/check-icon.svg';
import CopyIcon from '../../../../assets/images/payment/copy-icon.svg';
import InfoIcon from '../../../../assets/images/payment/info-icon.svg';
import PaymentSucceed from '../../../../assets/images/payment/payment-succeed.svg';
import styles from './modal.succeeded.module.scss';
export const StripeModalSucceeded = memo((props: {
  accessCode: string;
  show: boolean;
}) => {
  const {
    accessCode,
    show = false
  } = props;
  const {
    trackGoogleEvent
  } = useAnalytics();
  const [showContactFormModal, setShowContactFormModal] = useState(false);
  const handleContactFormModalHide = useCallback(() => {
    setShowContactFormModal(false);
  }, []);
  const [clipboardState, setClipboardState] = useState({
    email: false,
    accessCode: false
  });
  const {
    email
  } = useAtomValue(userAtom);
  const {
    showCrossSailOffer,
    wasUpsellSailOfferShown
  } = useAtomValue(stripeWasPaymentTriedAtom);
  const handleCopyEmail = useCallback(async () => {
    navigator.clipboard.writeText(email).then(r => r);
    setClipboardState({
      email: true,
      accessCode: false
    });
  }, [email]);
  const handleCopyAccessCode = useCallback(async () => {
    navigator.clipboard.writeText(accessCode).then(r => r);
    setClipboardState({
      email: false,
      accessCode: true
    });
  }, [accessCode]);
  const downloadLink = useMemo(() => `https://faceyoga.onelink.me/8Suw/pckc2cay?deep_link_value=${email}&deep_link_sub1=${accessCode}`, [accessCode, email]);
  useEffect(() => {
    if (show && !showCrossSailOffer) {
      trackGoogleEvent({
        eventName: 'after_purchase_creds_open',
        options: {
          email: email
        }
      });
    }
    if (show && !showCrossSailOffer && wasUpsellSailOfferShown) {
      confetti({
        particleCount: 150,
        spread: 180,
        zIndex: 9999
      });
    }
  }, [email, show, showCrossSailOffer, trackGoogleEvent, wasUpsellSailOfferShown]);
  return <Alert show={show} className={styles.container} canClose={false} title={<FormattedMessage defaultMessage="All done" id="Onboarding.Paywall.Credentials.Title" />} image={<PaymentSucceed className={styles.image} />} description={<FormattedMessage defaultMessage="Everything is ready, here are your account credentials:" id="Onboarding.Paywall.Credentials.Subtitle" />} footer={<footer className={styles.footer}>
            <p className={styles.credentials}>
              <FormattedMessage defaultMessage="Now you can download the {mark}" id="Onboarding.Paywall.Credentials.Text1" values={{
        mark: <strong>
                      <FormattedMessage defaultMessage="Mimika app" id="Onboarding.Paywall.Credentials.Text1.Mark" />
                    </strong>
      }} />{' '}
              <FormattedMessage defaultMessage="and use the credentials above to log in." id="Onboarding.Paywall.Credentials.Text2" />
            </p>
            <UiKitButton as="a" target="_blank" fullWidth onClick={() => trackGoogleEvent({
      eventName: 'after_purchase_creds_download'
    })} rel="noreferrer" href={downloadLink} className={styles.downloadButton}>
              <FormattedMessage defaultMessage="Download the app" id="Onboarding.Paywall.Credentials.Button" />
            </UiKitButton>
            <p className={styles.contactUs}>
              <b>
                <FormattedMessage defaultMessage="!IMPORTANT!" id="Onboarding.Paywall.Credentials.ContactUsImportant" />
              </b>{' '}
              <FormattedMessage defaultMessage="Login details were sent to your email." id="Onboarding.Paywall.Credentials.ContactUs.13" />{' '}
              <FormattedMessage defaultMessage="Please check your Inbox or Spam folder," id="Onboarding.Paywall.Credentials.ContactUs2.29" />{' '}
              <FormattedMessage defaultMessage="or" id="Onboarding.Paywall.Credentials.ContactUsOr" />{' '}
              <a onClick={() => setShowContactFormModal(true)}>
                <FormattedMessage defaultMessage="contact us." id="Onboarding.Paywall.Credentials.ContactUs3" />
              </a>
            </p>
          </footer>}>
        <div className={styles.accessBlock} data-testid="access-block">
          <p>
            <FormattedMessage defaultMessage="e-mail:" id="Onboarding.Paywall.Credentials.Mail" />
            &nbsp;<b>{email}</b>
            {clipboardState.email ? <CheckMark onClick={handleCopyEmail} /> : <CopyIcon onClick={handleCopyEmail} />}
          </p>
          <hr />
          <p>
            <FormattedMessage defaultMessage="access code:" id="Onboarding.Paywall.Credentials.Code" />
            &nbsp;<b>{accessCode}</b>{' '}
            {clipboardState.accessCode ? <CheckMark onClick={handleCopyAccessCode} /> : <CopyIcon onClick={handleCopyAccessCode} />}
          </p>
        </div>
        <div className={styles.disclaimer}>
          <InfoIcon />{' '}
          <span>
            <FormattedMessage defaultMessage="Copy with the button to the right" id="Onboarding.Paywall.Credentials.Tooltip" />
          </span>
        </div>
        <ContactFormModal placeLabel="payment_succeeded_screen" show={showContactFormModal} onHide={handleContactFormModalHide} />
      </Alert>;
});
StripeModalSucceeded.displayName = 'StripeModalSucceeded';