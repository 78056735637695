import { useRouter } from 'next/router';
import { useCallback } from 'react';

import {
  ALL_VARIANTS,
  TpSource,
  TpVariantObject,
} from 'src/constants/variants';
import { groupBy } from 'src/utils';

import {
  FACEBOOK_PIXEL_ID,
  GA_ID,
  GA_ID_V2,
  PINTEREST_ID,
  TIK_TOK_PIXEL,
  TWITTER_ID,
} from '../constants';

export const useShouldInit = (variant?: string) => {
  const router = useRouter();
  const { isReady, query } = router;

  const isValidVariantSource = useCallback(
    (source: TpSource) =>
      groupBy(ALL_VARIANTS, 'source')[source].some(
        (item: TpVariantObject) => item.variant_name === variant,
      ),
    [variant],
  );

  const TIK_TOK_SOURCES = isValidVariantSource('tiktok');
  const TWITTER_SOURCES = isValidVariantSource('twitter');
  const PINTEREST_SOURCES = isValidVariantSource('pinterest');
  const GOOGLE_SOURCES = isValidVariantSource('google');
  const FACEBOOK_SOURCES = isValidVariantSource('facebook');

  const shouldPixelInit =
    FACEBOOK_PIXEL_ID &&
    isReady &&
    FACEBOOK_SOURCES &&
    query['reset'] === undefined;
  const shouldPTInit =
    PINTEREST_ID &&
    PINTEREST_SOURCES &&
    isReady &&
    query['reset'] === undefined;
  const shouldTwitterInit =
    TWITTER_ID && TWITTER_SOURCES && isReady && query['reset'] === undefined;
  const shouldTikTokInit =
    TIK_TOK_PIXEL && TIK_TOK_SOURCES && isReady && query['reset'] === undefined;

  const shouldGoogleAnalyticsInit = GA_ID || GA_ID_V2;

  const shouldGoogleAnalyticsV2Init =
    typeof window !== 'undefined' &&
    window.location.hostname === 'quiz.mimika-app.com';

  const shouldClarityV2Init =
    typeof window !== 'undefined' &&
    window.location.hostname === 'quiz.mimika-app.com';

  return {
    shouldPixelInit,
    shouldPTInit,
    shouldTwitterInit,
    shouldTikTokInit,
    shouldGoogleAnalyticsInit,
    shouldGoogleAnalyticsV2Init,
    shouldClarityV2Init,
  };
};
